import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { db, rtdb } from "../firebase";
import { useNavigate } from "react-router-dom";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  doc,
  where,
  query,
  serverTimestamp as firestoreTimestamp,
  updateDoc,
  onSnapshot,
  getDoc,
  addDoc,
  runTransaction,
  limit,
  setLogLevel,
  writeBatch,
  orderBy,
  Timestamp,
} from "firebase/firestore";
import {
  onValue,
  push,
  ref,
  update,
  onChildAdded,
  serverTimestamp as rtdbTimestamp,
  remove,
  set,
  get,
  orderByChild,
  limitToFirst,
  equalTo,
  child,
} from "firebase/database";
import {
  FaCircle,
  FaArrowLeft,
  FaRegHeart,
  FaHeart,
  FaRegComment,
  FaUserCircle,
} from "react-icons/fa";
import ReactLoading from "react-loading";
import { FaFacebook, FaTelegram, FaSend } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { HiSpeakerphone } from "react-icons/hi";
import { VscSend } from "react-icons/vsc";
import Announcement from "../components/Announcement";
import Modal from "react-modal";
import ReCAPTCHA from "react-google-recaptcha";

setLogLevel("silent");

const ChatSession = () => {
  const chatContainerRef = useRef(null);
  const textInputRef = useRef(null);
  const failCountRef = useRef(0);
  const {
    user,
    setTheCurrentPage,
    displayName,
    university,
    setPoster,
    fiveRecentMessages,
    setFiveRecentMessages,
  } = UserAuth();
  const [chatSessionID, setChatSessionID] = useState(null);
  const [collectionName, setCollectionName] = useState(null);
  const [chatSessionStarted, setChatSessionStarted] = useState(false);
  const [chatSessionEnded, setChatSessionEnded] = useState(false);
  const [chatSessionForcelyEnded, setChatSessionForcelyEnded] = useState(false);
  const [showAreYouSure, setShowAreYouSure] = useState(false);
  const [userType, setUserType] = useState(null);
  const [posterDATA, setPosterDATA] = useState(null);
  const [posterEndTime, setPosterEndTime] = useState(null);
  const [isFetchingPoster, setIsFetchingPoster] = useState(true);

  // message
  const [inputMessage, setInputMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);

  // size handling
  const [isMobile, setIsMobile] = useState(false);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const [mobileHeight, setMobileHeight] = useState("86vh");

  // FOR STRANGER INFO USESTATES
  const [isStrangerTyping, setIsStrangerTyping] = useState(false);
  const [strangerDisplayName, setStrangerDisplayName] = useState(null);
  const [strangerUniversity, setStrangerUniversity] = useState(
    "I prefer not to disclose"
  );
  const [strangerUserID, setStrangerUserID] = useState(null);

  const [temporaryID, setTemporaryID] = useState(null);
  const [rerunKey, setRerunKey] = useState(0);
  const [posterRerunKey, setPosterRerunKey] = useState(0);
  const [searchStatus, setSearchStatus] = useState("");
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isHowToUseModalOpen, setIsHowToUseModalOpen] = useState(false);
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const [announcementDATA, setAnnouncementDATA] = useState(null);
  const [announcementEndTime, setAnnouncementEndTime] = useState(null);

  // recaptchav2
  const [token, setToken] = useState("");
  const [isReCaptchaV2ModalOpen, setIsRecaptchaV2ModalOpen] = useState(false);
  useEffect(() => {
    if (token.length) {
      setIsRecaptchaV2ModalOpen(false);
      setIsSpam(false);
      setIsSelling(false);
    }
  }, [token]);
  const handleToken = (token) => {
    setToken(token);
  };

  // recaptchav3
  const [isVerifierModalOpen, setIsVerifierModalOpen] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [botDetected, setBotDetected] = useState(false);
  const [isSpam, setIsSpam] = useState(false);
  const [isSelling, setIsSelling] = useState(false);

  const okayNext = async () => {
    // setClickCount((prev) => prev + 1);
    resetAllStates();
    // triggerRerun();
    setRerunKey((prevKey) => prevKey + 1);
  };

  const checkForSpam = (recentMessages) => {
    // List of spam-related keywords
    const spamKeywords = [
      "selling",
      "contents",
      "content",
      "available",
      "dm",
      "rates",
      "vcs",
      "vids",
      "nudes",
      "avail",
    ];

    // Case 1: Check if all 5 messages are the same
    if (
      recentMessages.length === 5 &&
      recentMessages.every((msg) => msg === recentMessages[0])
    ) {
      setIsSpam(true);
      setIsRecaptchaV2ModalOpen(true);
      return;
    }

    // Case 2: Check for spam keywords in each message
    recentMessages.forEach((message) => {
      const lowerMessage = message.toLowerCase();

      // Count matches with spam keywords
      const keywordMatches = spamKeywords.reduce((count, keyword) => {
        return count + (lowerMessage.includes(keyword) ? 1 : 0);
      }, 0);

      // Trigger reCAPTCHA modal if 2 or more keywords are detected
      if (keywordMatches >= 2) {
        setIsSelling(true);
        setFiveRecentMessages([]);
        setIsRecaptchaV2ModalOpen(true);
      }
    });
  };

  const addToFiveRecentMessage = (newMessage) => {
    setFiveRecentMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, newMessage];
      // Keep only the last 5 messages
      if (updatedMessages.length > 5) {
        updatedMessages.shift();
      }
      return updatedMessages;
    });
  };

  // comment
  const commentContainerRef = useRef(null);
  const [commentInput, setCommentInput] = useState("");
  const handleCommentChange = (event) => {
    setCommentInput(event.target.value);
  };
  const handleSendComment = () => {
    if (commentInput.length > 0) {
      const announcementRef = ref(rtdb, "Announcement/announcement/comments");
      const newComment = {
        displayName: displayName,
        university: university,
        userID: user.uid,
        comment: commentInput,
        timestamp: rtdbTimestamp(),
      };

      push(announcementRef, newComment) // Push the new comment to the database
        .then(() => {
          setCommentInput(""); // Clear the input after sending
          if (commentContainerRef.current) {
            commentContainerRef.current.scrollTo(0, 0); // Step 3: Scroll to the top
          }
        })
        .catch((error) => {
          console.error("Error adding comment: ", error); // Handle errors if needed
        });
    }
  };
  const timeAgo = (timestamp) => {
    const now = Date.now(); // Current time in milliseconds
    const seconds = Math.floor((now - timestamp) / 1000);

    if (seconds < 0) return "now"; // Handle future timestamps

    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) return `now`;
    if (minutes < 60) return `${minutes}m ago`;
    if (hours < 24) return `${hours}h ago`;
    return `${days}d ago`;
  };

  const handleHowToUseClick = (value) => {
    setIsHowToUseModalOpen(value);
  };

  const handleCommentsClick = (value) => {
    setIsCommentsModalOpen(value);
  };

  const resetAllStates = () => {
    setChatSessionID(null);
    setChatSessionStarted(false);
    setChatSessionEnded(false);
    setChatSessionForcelyEnded(false);
    setShowAreYouSure(false);
    setUserType(null);
    setInputMessage("");
    setMessages([]);
    setIsHowToUseModalOpen(false);
    setIsCommentsModalOpen(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (inputMessage.trim() === "") {
        e.preventDefault();
      } else {
        e.preventDefault();
        sendMessage();
      }
    }
  };

  const sendMessage = async () => {
    try {
      if (showAreYouSure) {
        setShowAreYouSure(false);
      }
      if (isMobile) {
        const contentEditableDiv = document.getElementById(
          "yourContentEditableId"
        );
        if (contentEditableDiv) {
          contentEditableDiv.textContent = "";
        }
      }

      const currentDate = new Date();
      // Add 8 hours to the current date
      currentDate.setHours(currentDate.getHours() + 8);
      // Convert the updated date to ISO string
      const updatedDateISOString = currentDate.toISOString();

      const newMessage = {
        text: inputMessage,
        sender: user.uid,
        timestamp: updatedDateISOString,
      };
      setInputMessage("");

      const messageRef = ref(rtdb, `MessagesV11/${chatSessionID}/messages`);

      await push(messageRef, newMessage);
      addToFiveRecentMessage(inputMessage);
    } catch (error) {
      console.log("Error");
    }
  };

  const endChat = async () => {
    try {
      const chatSessionRef = doc(db, collectionName, chatSessionID);
      setChatSessionID(null);
      setChatSessionForcelyEnded(true);
      await updateDoc(chatSessionRef, { ended: true });
    } catch (error) {
      console.log("Error");
    }
  };

  const getRandomCollection = () => {
    const collections = [
      "ChatRandom25",
      "ChatRandom26",
      "ChatRandom27",
      "ChatRandom28",
      "ChatRandom29",
    ];
    // Generate a random number between 0 and 2 inclusive
    const randomIndex = Math.floor(Math.random() * collections.length);
    const selectedCollection = collections[randomIndex];

    return selectedCollection;
  };

  useEffect(() => {
    if (fiveRecentMessages) {
      checkForSpam(fiveRecentMessages);
    }
  }, [fiveRecentMessages]);

  // ADSENSE
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("AdSense error:", e);
    }
  }, [rerunKey]);

  // ADSTERRA
  // useEffect(() => {
  //   // Configure the Adsterra options
  //   window.atOptions = {
  //     key: "5cdaaa61283d59d3dc86c00685f11229",
  //     format: "iframe",
  //     height: 250,
  //     width: 300,
  //     params: {},
  //   };

  //   // Create and append the Adsterra script
  //   const script = document.createElement("script");
  //   script.type = "text/javascript";
  //   script.src =
  //     "//www.highperformanceformat.com/5cdaaa61283d59d3dc86c00685f11229/invoke.js";
  //   script.async = true;

  //   const adContainer = document.getElementById("adsterra-container");
  //   adContainer.appendChild(script);

  //   // Cleanup function to remove the script on component unmount
  //   return () => {
  //     adContainer.removeChild(script);
  //   };
  // }, [rerunKey]);

  // useEffect(() => {
  //   const fetchPoster = async () => {
  //     // console.log("useeffect to fetch poster was triggered");
  //     const currentTime = new Date(); // Get the current time as a JavaScript Date object
  //     const firestoreCurrentTime = Timestamp.now(); // Convert to Firestore Timestamp

  //     const q = query(
  //       collection(db, "Posters"),
  //       where("status", "==", "Displaying"),
  //       where("duration.start", "<=", firestoreCurrentTime), // Ensure start time is before or equal to current time
  //       where("duration.end", ">", firestoreCurrentTime) // Ensure end time is after current time
  //     );

  //     const querySnapshot = await getDocs(q);

  //     // ADD VERIFIER HERE
  //     if (!querySnapshot.empty) {
  //       // IF THERES A POSTER
  //       setIsImageLoaded(false);
  //       // console.log("a poster was fetched");
  //       const posterData = querySnapshot.docs[0].data();
  //       setPosterDATA(posterData);
  //       setPosterEndTime(posterData.duration.end.toDate()); // Store the end time
  //       if (isFetchingPoster === false) {
  //         setRerunKey((prevKey) => prevKey + 1);
  //       } else {
  //         setIsFetchingPoster(false);
  //       }
  //     } else {
  //       // IF THERE IS NONE
  //       // console.log("no poster was fetched");
  //       setPosterDATA(null);
  //       setPosterEndTime(null);
  //       if (isFetchingPoster === false) {
  //         setRerunKey((prevKey) => prevKey + 1);
  //       } else {
  //         setIsFetchingPoster(false);
  //         setIsImageLoaded(true);
  //       }
  //     }
  //   };

  //   fetchPoster();
  // }, [posterRerunKey]);

  // Reset click counter every 50 seconds
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setClickCount(0); // Reset click count
  //   }, 50000); // 50seconds interval

  //   return () => clearInterval(interval); // Cleanup interval on unmount
  // }, []);

  useEffect(() => {
    const findMatch = () => {
      try {
        let tempID = null;
        runTransaction(db, async (transaction) => {
          // console.log("runtransaction begins");
          const selectedCollectionName = getRandomCollection();
          // console.log(selectedCollectionName);
          // const selectedCollectionName = "TestingChatSession";
          setCollectionName(selectedCollectionName);
          if (failCountRef.current > 3) {
            // console.log("maximum has reached");
            const chatSessionsCollection = collection(
              db,
              selectedCollectionName
            );
            const chatSessionRef = doc(chatSessionsCollection);
            // console.log("LIMIT EXCEED. FORCELY CREATES DOC");
            await setDoc(chatSessionRef, {
              timestamp: firestoreTimestamp(),
              ended: false,
              creatorUserID: user.uid,
              creatorDisplayName: displayName,
              creatorUniversity: university,
              joinerUserID: null,
              joinerDisplayName: null,
              joinerUniversity: null,
            });
            setUserType("creator");
            tempID = chatSessionRef.id;
            // console.log("doc created successfully");
          } else {
            // console.log("transaction starts");
            // console.log("failCount: " + failCountRef.current);
            failCountRef.current++;
            const chatSessionsCollection = collection(
              db,
              selectedCollectionName
            );
            const querySnapshot = await getDocs(
              query(
                chatSessionsCollection,
                where("creatorUserID", "!=", user.uid),
                where("joinerUserID", "==", null),
                limit(1)
              )
            );
            if (querySnapshot.empty) {
              // console.log("empty doc");
              const chatSessionRef = doc(chatSessionsCollection);
              // console.log("creates own chatsession");
              transaction.set(chatSessionRef, {
                timestamp: firestoreTimestamp(),
                ended: false,
                creatorUserID: user.uid,
                creatorDisplayName: displayName,
                creatorUniversity: university,
                joinerUserID: null,
                joinerDisplayName: null,
                joinerUniversity: null,
              });
              setUserType("creator");
              tempID = chatSessionRef.id;
            } else {
              // console.log(querySnapshot.docs.length);
              // console.log("found one");
              const foundDocument = querySnapshot.docs[0];
              const foundDocumentRef = doc(
                db,
                selectedCollectionName,
                foundDocument.id
              );
              const sessionData = (
                await transaction.get(foundDocumentRef)
              ).data();
              // console.log("got document data");
              if (sessionData.joinerUserID === null) {
                // console.log("tries to update");
                transaction.update(foundDocumentRef, {
                  joinerUserID: user.uid,
                  joinerDisplayName: displayName,
                  joinerUniversity: university,
                });
                setUserType("joiner");
                // console.log("update finished");
                tempID = foundDocument.id;
                // console.log("TEMPID: " + tempID);
              } else {
                // problem is here
                // console.log("Session already has a joiner, transaction aborted");
                throw new Error("Error throwed");
              }
            }
          }
        })
          .then(() => {
            if (tempID === null) {
              // console.log("no tempID, will rerun");
              findMatch();
            } else {
              // console.log("transaction finish");
              failCountRef.current = 0;
              setChatSessionID(tempID);
            }
          })
          .catch(async (error) => {
            console.error("Failed:", error, ". Function will now rerun");
            findMatch();
          });
      } catch (error) {
        console.log("Error", error);
      }
    };

    setTimeout(() => {
      findMatch();
    }, 3000);
    // if (!isFetchingPoster) {
    //   const currentTime = new Date();
    //   if (posterDATA && posterEndTime && currentTime < posterEndTime) {
    //     if (isImageLoaded) {
    //       setTimeout(() => {
    //         findMatch();
    //       }, 5000); // 1500 milliseconds = 1.5 seconds
    //     }
    //   } else {
    //     setTimeout(() => {
    //       findMatch();
    //     }, 5000); // 1500 milliseconds = 1.5 seconds
    //   }
    // }
  }, [rerunKey]);

  // const triggerRerun = () => {
  //   const currentTime = new Date();
  //   if (posterDATA && posterEndTime && currentTime < posterEndTime) {
  //     // console.log("Poster still valid, no need to refetch");
  //     setRerunKey((prevKey) => prevKey + 1);
  //   } else {
  //     // console.log("Poster expired, fetching a new one");
  //     // Fetch a new poster if current time has exceeded the end time
  //     setPosterRerunKey((prevKey) => prevKey + 1);
  //   }
  // };

  useEffect(() => {
    if (chatSessionID) {
      const chatSessionRef = doc(db, collectionName, chatSessionID);
      const messagesRef = ref(
        rtdb,
        "MessagesV11/" + chatSessionRef.id + "/messages"
      );
      const announcementRef = ref(rtdb, "Announcement");

      const chatSessionUnsubscribe = onSnapshot(
        chatSessionRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            if (data.creatorUserID && data.joinerUserID) {
              setChatSessionStarted(true);
            }
            if (data.ended && !chatSessionForcelyEnded && chatSessionID) {
              setChatSessionEnded(true);
            }
            if (userType === "joiner") {
              setStrangerDisplayName(data.creatorDisplayName);
              setStrangerUniversity(data.creatorUniversity);
              setStrangerUserID(data.creatorUserID);
            } else if (userType === "creator") {
              setStrangerDisplayName(data.joinerDisplayName);
              setStrangerUniversity(data.joinerUniversity);
              setStrangerUserID(data.joinerUserID);
            }
          }
        }
      );

      // rtdb message listener
      const messageUnsubscribe = onChildAdded(messagesRef, (snapshot) => {
        const newMessage = snapshot.val();
        // try
        if (newMessage.sender !== user.uid) {
          setIsStrangerTyping(false);
        }
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: newMessage.sender || "",
            text: newMessage.text || "",
            timestamp: newMessage.timestamp || "",
          },
        ]);
      });

      const announcementUnsubscribe = onValue(announcementRef, (snapshot) => {
        if (snapshot.val() === null) {
          setAnnouncementDATA(null);
        } else {
          setAnnouncementDATA(snapshot.val().announcement);
        }
      });

      return () => {
        if (
          chatSessionUnsubscribe ||
          messageUnsubscribe ||
          announcementUnsubscribe
        ) {
          chatSessionUnsubscribe();
          messageUnsubscribe();
          announcementUnsubscribe();
        }
      };
    }
  }, [chatSessionID]);

  // HANDLES USER TYPING
  useEffect(() => {
    if (
      strangerUserID &&
      chatSessionStarted &&
      !chatSessionEnded &&
      !chatSessionForcelyEnded
    ) {
      const userTypingRef = ref(rtdb, "UserTypingV5/" + strangerUserID);
      const rtdbIsTypingUnsubscribe = onValue(userTypingRef, (snapshot) => {
        setIsStrangerTyping(snapshot.val().isTyping);
      });

      return () => {
        if (rtdbIsTypingUnsubscribe) {
          rtdbIsTypingUnsubscribe();
        }
      };
    }
  }, [
    strangerUserID,
    chatSessionStarted,
    chatSessionEnded,
    chatSessionForcelyEnded,
  ]);

  // serves as a ploy so that the rtdb doesnt get updated everytime the user types a character, it only updates based on if there is input message or none
  useEffect(() => {
    if (inputMessage) {
      setIsTyping(true);
    } else {
      setIsTyping(false);
    }
  }, [inputMessage]);

  // updates own user's usertyping state on rtdb
  useEffect(() => {
    const userTypingRef = ref(rtdb, "UserTypingV5/" + user.uid);
    if (isTyping) {
      update(userTypingRef, { isTyping: true, timestamp: rtdbTimestamp() });
    } else {
      update(userTypingRef, { isTyping: false, timestamp: rtdbTimestamp() });
    }
  }, [isTyping]);

  // MANAGES MOBILE VIEW
  // this causes flickering
  // useEffect(() => {
  //   if (isStrangerTyping) {
  //     if (isMobile) {
  //       setMobileHeight("80vh");
  //     }
  //   } else {
  //     if (isMobile) {
  //       setMobileHeight("86vh");
  //     }
  //   }
  // }, [isStrangerTyping]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1050);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // working keyboard detector but is temporarily disabled
  // useEffect(() => {
  //   // Capture the initial viewport height and scale when the component mounts
  //   let initialHeight = window.visualViewport?.height || window.innerHeight;
  //   let initialScale = window.visualViewport?.scale || 1;

  //   const handleResize = () => {
  //     const currentHeight = window.visualViewport?.height || window.innerHeight;
  //     const currentScale = window.visualViewport?.scale || 1;

  //     // Only check for keyboard if the scale has not changed (to avoid zoom detection)
  //     if (currentScale === initialScale) {
  //       // Check if the viewport height has shrunk (keyboard is open)
  //       setIsKeyboardVisible(currentHeight < initialHeight);
  //     }
  //   };

  //   // Add event listeners to track resize changes
  //   window.visualViewport?.addEventListener("resize", handleResize);

  //   return () => {
  //     // Clean up event listeners on component unmount
  //     window.visualViewport?.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        if (showAreYouSure && !chatSessionForcelyEnded) {
          endChat();
        } else {
          if (!chatSessionForcelyEnded) {
            setShowAreYouSure(true);
          } else {
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showAreYouSure, chatSessionForcelyEnded]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [
    messages,
    chatSessionEnded,
    chatSessionForcelyEnded,
    mobileHeight,
    isStrangerTyping,
  ]);

  // officially working
  // to prevent back button
  useEffect(() => {
    const onBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  return (
    <>
      {chatSessionStarted ? (
        // mobile
        isMobile ? (
          <>
            <Modal
              ariaHideApp={false}
              isOpen={isVerifierModalOpen}
              style={{
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  maxWidth: 275,
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  borderRadius: 10,
                },
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                },
              }}
            >
              {botDetected ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ fontSize: 15, fontWeight: "bold" }}>
                    Bot detected
                  </p>
                  <p style={{ fontSize: 11 }}>
                    We have detected that you are a bot
                  </p>
                  <p style={{ fontSize: 11, marginTop: 10 }}>
                    To access chatkool again, refresh the site
                  </p>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ReactLoading
                    color={"#FF66C4"}
                    type={"spin"}
                    height={35}
                    width={35}
                  />
                  <p
                    style={{
                      fontSize: 13,
                      fontWeight: "600",
                      marginBottom: 0,
                    }}
                  >
                    You are moving on too fast
                  </p>
                  <p style={{ fontSize: 10, fontWeight: "400" }}>
                    Let me verify if you are a human, please wait
                  </p>
                </div>
              )}
            </Modal>

            <Modal
              ariaHideApp={false}
              isOpen={isHowToUseModalOpen}
              onRequestClose={() => setIsHowToUseModalOpen(false)} // Close modal on outside click
              style={{
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  maxWidth: 275,
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  borderRadius: 20,
                },
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                },
              }}
            >
              <div>
                <button
                  onClick={() => {
                    setIsHowToUseModalOpen(false);
                  }}
                  style={{
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                  }}
                >
                  <IoMdClose size={30} color={"black"} />
                </button>
                <p
                  style={{
                    fontWeight: "bolder",
                    color: "black",
                    margin: 0,
                    fontSize: 18,
                    textAlign: "center",
                    userSelect: "none",
                  }}
                >
                  Loudspeaker
                </p>
                <p
                  style={{
                    margin: 0,
                    textAlign: "center",
                    fontSize: 11,
                    fontWeight: "400",
                    color: "black",
                    fontStyle: "italic",
                    userSelect: "none",
                  }}
                >
                  Visible to everyone
                </p>

                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginBottom: 5,
                      textAlign: "center",
                      fontSize: 13,
                    }}
                  >
                    Rate
                  </p>
                  <ul
                    style={{
                      listStyleType: "none",
                      paddingLeft: 0,
                      margin: 0,
                      textAlign: "center",
                    }}
                  >
                    <li style={{ fontSize: 13 }}>₱20.00 → 10mins</li>
                  </ul>
                </div>

                {/* Rules Section */}
                <div>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginBottom: 5,
                      textAlign: "center",
                      fontSize: 13,
                    }}
                  >
                    Rules
                  </p>
                  <ul
                    style={{
                      listStyleType: "disc",
                      paddingLeft: 20,
                      fontSize: 13,
                    }}
                  >
                    <li>Max length is 150 characters.</li>
                    <li>
                      Must not contain any content that could lead to
                      cyberlibel, such as defamation.
                    </li>
                  </ul>
                </div>

                {/* Instructions Section */}
                <div style={{ marginTop: 20 }}>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginBottom: 5,
                      textAlign: "center",
                      fontSize: 13,
                    }}
                  >
                    How to Use Loudspeaker
                  </p>
                  <ol style={{ paddingLeft: 20, fontSize: 13 }}>
                    <li>
                      Send your announcement to @officialchatkool on Telegram.
                    </li>
                    <li>
                      Indicate your preferred time to display the announcement
                    </li>
                    <li>
                      Complete your payment by scanning our QR code using any
                      digital wallet like GCash, Maya, ShopeePay, and more.
                    </li>
                  </ol>
                </div>
              </div>
            </Modal>
            {/* {announcementDATA ? (
              <Modal
                isOpen={isCommentsModalOpen}
                onRequestClose={() => {
                  setIsCommentsModalOpen(false);
                }}
                style={{
                  content: {
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    width: 300,
                    marginRight: "-50%",
                    transform: "translate(-50%, -50%)",
                    borderRadius: 20,
                  },
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                  },
                }}
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-start",
                      marginBottom: 10,
                    }}
                  >
                    <button
                      onClick={() => {
                        setIsCommentsModalOpen(false);
                      }}
                      style={{
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                      }}
                    >
                      <IoMdClose size={30} color={"black"} />
                    </button>
                  </div>
                  {announcementDATA ? (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 11,
                        color: "gray",
                        margin: 0,
                        marginBottom: 10,
                      }}
                    >
                      Ends in{" "}
                      <span style={{ fontWeight: "500" }}>
                        {`${
                          new Date(
                            announcementDATA.duration.end._seconds * 1000
                          ).getHours() % 12 || 12
                        }:${
                          new Date(
                            announcementDATA.duration.end._seconds * 1000
                          ).getMinutes() < 10
                            ? "0" +
                              new Date(
                                announcementDATA.duration.end._seconds * 1000
                              ).getMinutes()
                            : new Date(
                                announcementDATA.duration.end._seconds * 1000
                              ).getMinutes()
                        } ${
                          new Date(
                            announcementDATA.duration.end._seconds * 1000
                          ).getHours() < 12
                            ? "AM"
                            : "PM"
                        }`}
                      </span>
                    </p>
                  ) : null}
                  <div
                    style={{
                      backgroundColor: "white",
                      height: "auto",
                      width: 300,
                      backgroundColor: "#fcce79",
                      borderRadius: 20,
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        textAlign: "center",
                        fontWeight: "500",
                        fontSize: 15,
                        padding: 20,
                        color: "#10375C",
                        userSelect: "none",
                      }}
                    >
                      {announcementDATA.announcement}
                    </p>
                  </div>
                  <p
                    style={{
                      fontWeight: "bolder",
                      color: "black",
                      fontSize: 15,
                      textAlign: "center",
                      borderBottom: "0.5px solid gray",
                      userSelect: "none",
                      margin: 0,
                      padding: 10,
                    }}
                  >
                    Comments
                  </p>
                  <div
                    ref={commentContainerRef}
                    style={{
                      width: 300,
                      height: 275,
                      overflowY: "auto",
                      overFlowX: "hidden",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    {announcementDATA.comments &&
                    Object.entries(announcementDATA.comments).length > 0 ? (
                      Object.entries(announcementDATA.comments)
                        .reverse()
                        .map(([key, comment]) => (
                          <div
                            key={key}
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              flex: 1,
                              flexDirection: "row",
                              marginBottom: 20,
                              marginTop: 10,
                            }}
                          >
                            {comment.university ===
                            "I prefer not to disclose" ? (
                              <FaUserCircle
                                size={25}
                                style={{ marginRight: 5 }}
                              />
                            ) : (
                              <img
                                style={{ height: 25, marginRight: 5 }}
                                src={require(`../assets/universities/${comment.university}.png`)}
                                alt={`${comment.university}`}
                              />
                            )}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                              }}
                            >
                              <p
                                style={{
                                  fontSize: 11,
                                  color: "gray",
                                  margin: 0,
                                  marginBottom: 5,
                                  fontWeight: "400",
                                }}
                              >
                                {comment.displayName} •{" "}
                                {timeAgo(comment.timestamp)}
                              </p>
                              <p
                                style={{
                                  fontSize: 14,
                                  color: "black",
                                  margin: 0,
                                }}
                              >
                                {comment.comment}
                              </p>
                            </div>
                          </div>
                        ))
                    ) : (
                      <p
                        style={{
                          textAlign: "center",
                          color: "gray",
                          marginTop: 75,
                          fontSize: 13,
                          fontWeight: "300",
                        }}
                      >
                        No comments yet
                      </p> // Fallback if no comments
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flex: 1,
                      flexDirection: "row",
                      // justifyContent: "flex-start",
                    }}
                  >
                    {university === "I prefer not to disclose" ? (
                      <FaUserCircle size={30} style={{ marginRight: 5 }} />
                    ) : (
                      <img
                        style={{ height: 30, marginRight: 5 }}
                        src={require(`../assets/universities/${university}.png`)}
                        alt={`${university}`}
                      />
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: 5,
                        width: "100%",
                      }}
                    >
                      <input
                        placeholder="Add a comment"
                        style={{
                          borderRadius: 10,
                          border: "1px solid black",
                          fontSize: 13,
                          padding: 10,
                        }}
                        value={commentInput}
                        onChange={handleCommentChange}
                      />
                      <p
                        style={{
                          fontSize: 10,
                          color: "gray",
                          margin: 0,
                          marginTop: 2.5,
                        }}
                      >
                        Commenting as{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {displayName}
                        </span>
                      </p>
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        marginTop: 2,
                      }}
                    >
                      <button
                        disabled={commentInput.length === 0}
                        style={{ border: "none", background: "none" }}
                        onClick={handleSendComment}
                      >
                        <VscSend
                          size={25}
                          color={commentInput.length === 0 ? "gray" : "black"}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            ) : null} */}
            {isReCaptchaV2ModalOpen ? (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent background
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 1000,
                }}
              >
                {isSpam ? (
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "20px",
                      borderRadius: "10px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 15,
                        fontWeight: "500",
                      }}
                    >
                      Opps..
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: "400",
                      }}
                    >
                      Your activity has been flagged as spam
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: "400",
                      }}
                    ></p>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: "400",
                      }}
                    >
                      Please verify to continue
                    </p>
                    <ReCAPTCHA
                      sitekey={"6Lfhf4QqAAAAADEwFmpMBNuG00M4KqDbr2YoqqNx"}
                      onChange={handleToken}
                    />
                  </div>
                ) : isSelling ? (
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "20px",
                      borderRadius: "10px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 15,
                        fontWeight: "500",
                      }}
                    >
                      Warning
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: "400",
                      }}
                    >
                      Your activity has been flagged for promoting illegal
                      content.
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: "400",
                      }}
                    ></p>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: "400",
                      }}
                    >
                      If this is a mistake, please complete the verification to
                      continue
                    </p>
                    <ReCAPTCHA
                      sitekey={"6Lfhf4QqAAAAADEwFmpMBNuG00M4KqDbr2YoqqNx"}
                      onChange={handleToken}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            <div
              style={{
                backgroundColor: "#fcf0f8",
                display: "flex",
                flexDirection: "column",
                height: `${viewportHeight}px`, // Make the outer div full height
              }}
            >
              <div
                style={{
                  overflowY: "scroll",
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1, // Allow this div to grow and take up available space
                  paddingLeft: 20,
                  paddingTop: 20,
                  paddingRight: 15,
                  marginBottom: -10,
                }}
                ref={chatContainerRef}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <img
                    src={require("../mainlogo.png")}
                    alt="Logo"
                    style={{
                      borderRadius: "100%",
                      height: "25px",
                      marginRight: "10px",
                    }}
                  />
                  <p style={{ color: "#004AAD", fontSize: 15 }}>
                    <strong>ChatKOOL</strong>
                  </p>
                </div>
                <div
                  style={{
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "15px",
                      textAlign: "left",
                      margin: 0,
                      padding: 0,
                      fontWeight: "300",
                    }}
                  >
                    You are now chatting with{" "}
                    {strangerDisplayName === null ? "a" : null}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    {strangerUniversity === "I prefer not to disclose" ||
                    strangerUniversity === null ? null : (
                      <img
                        style={{ height: 50 }}
                        src={require(`../assets/universities/${strangerUniversity}.png`)}
                        alt={strangerUniversity}
                      />
                    )}
                    {strangerDisplayName ? (
                      <p
                        style={{
                          fontSize: "20px",
                          margin: 0,
                          padding: 0,
                          marginLeft: 10,
                        }}
                      >
                        {strangerDisplayName}
                      </p>
                    ) : (
                      <p
                        style={{
                          fontSize: "20px",
                          color: "#004AAD",
                          fontWeight: "500",
                          margin: 0,
                          padding: 0,
                          marginLeft: 10,
                        }}
                      >
                        Stranger
                      </p>
                    )}
                  </div>
                </div>
                <ul
                  style={{
                    listStyleType: "none",
                    padding: 0,
                  }}
                >
                  {messages.map((message) => (
                    <li
                      key={message.timestamp}
                      style={{
                        textAlign:
                          message.sender === user.uid ? "right" : "left",
                        padding: "4px",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor:
                            message.sender === user.uid
                              ? "#004AAD"
                              : "lightgray",
                          color:
                            message.sender === user.uid ? "white" : "black",
                          borderRadius: "8px",
                          padding: "8px",
                          display: "inline-block",
                          maxWidth: "70%",
                          wordWrap: "break-word",
                          textAlign: "left",
                          marginLeft:
                            message.sender === user.uid ? "auto" : "0",
                        }}
                      >
                        {message.text}
                      </span>
                    </li>
                  ))}
                  {isStrangerTyping ? (
                    <div
                      style={{
                        backgroundColor: "lightgray",
                        display: "inline-block",
                        borderRadius: 8,
                        paddingRight: 5,
                        paddingLeft: 5,
                        marginLeft: 5,
                        marginTop: 2,
                      }}
                    >
                      <ReactLoading
                        type="bubbles"
                        color="#808080"
                        height={33}
                        width={33}
                      />
                    </div>
                  ) : null}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {chatSessionEnded && chatSessionID ? (
                      <p
                        style={{
                          fontSize: "15px",
                          color: "#FF66C4",
                          textAlign: "center",
                          fontWeight: "500",
                        }}
                      >
                        {strangerDisplayName ? strangerDisplayName : "Stranger"}{" "}
                        left the chat
                      </p>
                    ) : null}
                    {chatSessionForcelyEnded ? (
                      <p
                        style={{
                          fontSize: 15,
                          color: "#FF66C4",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        <strong>You ended the chat</strong>
                      </p>
                    ) : null}
                  </div>
                </ul>
              </div>
              <div
                style={{
                  backgroundColor: "#fcf0f8",
                  display: "flex",
                  flexDirection: "column",
                  paddingBottom: "10px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Announcement
                    text={
                      announcementDATA ? announcementDATA.announcement : null
                    }
                    onHowToUseClick={handleHowToUseClick}
                    onCommentsClick={handleCommentsClick}
                    endTime={
                      announcementDATA ? announcementDATA.duration.end : null
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  >
                    {chatSessionEnded || chatSessionForcelyEnded ? (
                      <>
                        <button
                          onClick={() => {
                            // if (clickCount > 3) {
                            //   okayNextWithRecaptcha();
                            // } else {
                            //   okayNext();
                            // }
                            okayNext();
                          }}
                          style={{
                            marginRight: 5,
                            flex: 1,
                            height: "40px",
                            borderRadius: "8px",
                            backgroundColor: "#FF66C4",
                            color: "white",
                            borderWidth: 0,
                          }}
                        >
                          <strong>Okay, next</strong>
                        </button>
                        <button
                          onClick={() => {
                            setTheCurrentPage("Selection");
                          }}
                          style={{
                            marginLeft: 5,
                            flex: 1,
                            height: "40px",
                            borderRadius: "8px",
                            backgroundColor: "#004AAD",
                            color: "white",
                            borderWidth: 0,
                          }}
                        >
                          <strong>Main Menu</strong>
                        </button>
                      </>
                    ) : (
                      <>
                        {showAreYouSure ? (
                          <button
                            onClick={endChat}
                            style={{
                              flex: 0.1,
                              height: "40px",
                              borderRadius: "8px",
                              border: "1px solid #ccc",
                              marginRight: "5px",
                            }}
                          >
                            <strong>Sure?</strong>
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setShowAreYouSure(true);
                            }}
                            style={{
                              flex: 0.1,
                              height: "40px",
                              borderRadius: "8px",
                              border: "1px solid #ccc",
                              marginRight: "5px",
                              backgroundColor: "white",
                            }}
                          >
                            <strong>End</strong>
                          </button>
                        )}
                        <div
                          id="yourContentEditableId"
                          contentEditable
                          value={inputMessage}
                          onInput={(e) => {
                            if (showAreYouSure) {
                              setShowAreYouSure(false);
                            }
                            setInputMessage(e.target.textContent);
                          }}
                          onKeyDown={handleKeyPress}
                          style={{
                            flex: 0.7,
                            padding: "10px",
                            marginRight: "5px",
                            border: "1px solid #696969",
                            borderRadius: "8px",
                            maxHeight: "50px",
                            overflowY: "auto",
                            backgroundColor: "white",
                          }}
                        />
                        <button
                          onClick={sendMessage}
                          disabled={!inputMessage.trim()}
                          style={{
                            flex: 0.2,
                            backgroundColor: "white",
                            height: "40px",
                            borderRadius: "8px",
                            border: "1px solid #ccc",
                          }}
                        >
                          <strong>Send</strong>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          // desktop/laptop
          <>
            <Modal
              ariaHideApp={false}
              isOpen={isVerifierModalOpen}
              style={{
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  maxWidth: 275,
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  borderRadius: 10,
                },
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                },
              }}
            >
              {botDetected ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ fontSize: 15, fontWeight: "bold" }}>
                    Bot detected
                  </p>
                  <p style={{ fontSize: 11 }}>
                    We have detected that you are a bot
                  </p>
                  <p style={{ fontSize: 11, marginTop: 10 }}>
                    To access chatkool again, refresh the site
                  </p>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ReactLoading
                    color={"#FF66C4"}
                    type={"spin"}
                    height={35}
                    width={35}
                  />
                  <p
                    style={{
                      fontSize: 13,
                      fontWeight: "600",
                      marginBottom: 0,
                    }}
                  >
                    You are moving on too fast
                  </p>
                  <p style={{ fontSize: 10, fontWeight: "400" }}>
                    Let me verify if you are a human, please wait
                  </p>
                </div>
              )}
            </Modal>
            <Modal
              isOpen={isHowToUseModalOpen}
              onRequestClose={() => setIsHowToUseModalOpen(false)} // Close modal on outside click
              style={{
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  maxWidth: 275,
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  borderRadius: 20,
                },
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                },
              }}
            >
              <div>
                <button
                  onClick={() => {
                    setIsHowToUseModalOpen(false);
                  }}
                  style={{
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                  }}
                >
                  <IoMdClose size={30} color={"black"} />
                </button>

                <p
                  style={{
                    fontWeight: "bolder",
                    color: "black",
                    margin: 0,
                    fontSize: 18,
                    textAlign: "center",
                    userSelect: "none",
                  }}
                >
                  Loudspeaker
                </p>
                <p
                  style={{
                    margin: 0,
                    textAlign: "center",
                    fontSize: 11,
                    fontWeight: "400",
                    color: "black",
                    fontStyle: "italic",
                    userSelect: "none",
                  }}
                >
                  Visible to everyone
                </p>

                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginBottom: 5,
                      textAlign: "center",
                      fontSize: 13,
                    }}
                  >
                    Rate
                  </p>
                  <ul
                    style={{
                      listStyleType: "none",
                      paddingLeft: 0,
                      margin: 0,
                      textAlign: "center",
                    }}
                  >
                    <li style={{ fontSize: 13 }}>₱20.00 → 10mins</li>
                  </ul>
                </div>

                {/* Rules Section */}
                <div>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginBottom: 5,
                      textAlign: "center",
                      fontSize: 13,
                    }}
                  >
                    Rules
                  </p>
                  <ul
                    style={{
                      listStyleType: "disc",
                      paddingLeft: 20,
                      fontSize: 13,
                    }}
                  >
                    <li>Max length is 150 characters.</li>
                    <li>
                      Must not contain any content that could lead to
                      cyberlibel, such as defamation.
                    </li>
                  </ul>
                </div>

                {/* Instructions Section */}
                <div style={{ marginTop: 20 }}>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginBottom: 5,
                      textAlign: "center",
                      fontSize: 13,
                    }}
                  >
                    How to Use Loudspeaker
                  </p>
                  <ol style={{ paddingLeft: 20, fontSize: 13 }}>
                    <li>
                      Send your announcement to @officialchatkool on Telegram.
                    </li>
                    <li>
                      Indicate your preferred time to display the announcement
                    </li>
                    <li>
                      Complete your payment by scanning our QR code using any
                      digital wallet like GCash, Maya, ShopeePay, and more.
                    </li>
                  </ol>
                </div>
              </div>
            </Modal>
            {/* {announcementDATA ? (
              <Modal
                isOpen={isCommentsModalOpen}
                onRequestClose={() => {
                  setIsCommentsModalOpen(false);
                }}
                style={{
                  content: {
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    width: 300,
                    marginRight: "-50%",
                    transform: "translate(-50%, -50%)",
                    borderRadius: 20,
                  },
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                  },
                }}
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-start",
                      marginBottom: 10,
                    }}
                  >
                    <button
                      onClick={() => {
                        setIsCommentsModalOpen(false);
                      }}
                      style={{
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                      }}
                    >
                      <IoMdClose size={30} color={"black"} />
                    </button>
                  </div>
                  {announcementDATA ? (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 11,
                        color: "gray",
                        margin: 0,
                        marginBottom: 10,
                      }}
                    >
                      Ends in{" "}
                      <span style={{ fontWeight: "500" }}>
                        {`${
                          new Date(
                            announcementDATA.duration.end._seconds * 1000
                          ).getHours() % 12 || 12
                        }:${
                          new Date(
                            announcementDATA.duration.end._seconds * 1000
                          ).getMinutes() < 10
                            ? "0" +
                              new Date(
                                announcementDATA.duration.end._seconds * 1000
                              ).getMinutes()
                            : new Date(
                                announcementDATA.duration.end._seconds * 1000
                              ).getMinutes()
                        } ${
                          new Date(
                            announcementDATA.duration.end._seconds * 1000
                          ).getHours() < 12
                            ? "AM"
                            : "PM"
                        }`}
                      </span>
                    </p>
                  ) : null}
                  <div
                    style={{
                      backgroundColor: "white",
                      height: "auto",
                      width: 300,
                      backgroundColor: "#fcce79",
                      borderRadius: 20,
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        textAlign: "center",
                        fontWeight: "500",
                        fontSize: 15,
                        padding: 20,
                        color: "#10375C",
                        userSelect: "none",
                      }}
                    >
                      {announcementDATA.announcement}
                    </p>
                  </div>
                  <p
                    style={{
                      fontWeight: "bolder",
                      color: "black",
                      fontSize: 15,
                      textAlign: "center",
                      borderBottom: "0.5px solid gray",
                      userSelect: "none",
                      margin: 0,
                      padding: 10,
                    }}
                  >
                    Comments
                  </p>
                  <div
                    ref={commentContainerRef}
                    style={{
                      width: 300,
                      height: 275,
                      overflowY: "auto",
                      overFlowX: "hidden",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    {announcementDATA.comments &&
                    Object.entries(announcementDATA.comments).length > 0 ? (
                      Object.entries(announcementDATA.comments)
                        .reverse()
                        .map(([key, comment]) => (
                          <div
                            key={key}
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              flex: 1,
                              flexDirection: "row",
                              marginBottom: 20,
                              marginTop: 10,
                            }}
                          >
                            {comment.university ===
                            "I prefer not to disclose" ? (
                              <FaUserCircle
                                size={25}
                                style={{ marginRight: 5 }}
                              />
                            ) : (
                              <img
                                style={{ height: 25, marginRight: 5 }}
                                src={require(`../assets/universities/${comment.university}.png`)}
                                alt={`${comment.university}`}
                              />
                            )}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                              }}
                            >
                              <p
                                style={{
                                  fontSize: 11,
                                  color: "gray",
                                  margin: 0,
                                  marginBottom: 5,
                                  fontWeight: "400",
                                }}
                              >
                                {comment.displayName} •{" "}
                                {timeAgo(comment.timestamp)}
                              </p>
                              <p
                                style={{
                                  fontSize: 14,
                                  color: "black",
                                  margin: 0,
                                }}
                              >
                                {comment.comment}
                              </p>
                            </div>
                          </div>
                        ))
                    ) : (
                      <p
                        style={{
                          textAlign: "center",
                          color: "gray",
                          marginTop: 75,
                          fontSize: 13,
                          fontWeight: "300",
                        }}
                      >
                        No comments yet
                      </p> // Fallback if no comments
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flex: 1,
                      flexDirection: "row",
                      // justifyContent: "flex-start",
                    }}
                  >
                    {university === "I prefer not to disclose" ? (
                      <FaUserCircle size={30} style={{ marginRight: 5 }} />
                    ) : (
                      <img
                        style={{ height: 30, marginRight: 5 }}
                        src={require(`../assets/universities/${university}.png`)}
                        alt={`${university}`}
                      />
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: 5,
                        width: "100%",
                      }}
                    >
                      <input
                        placeholder="Add a comment"
                        style={{
                          borderRadius: 10,
                          border: "1px solid black",
                          fontSize: 13,
                          padding: 10,
                        }}
                        value={commentInput}
                        onChange={handleCommentChange}
                      />
                      <p
                        style={{
                          fontSize: 10,
                          color: "gray",
                          margin: 0,
                          marginTop: 2.5,
                        }}
                      >
                        Commenting as{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {displayName}
                        </span>
                      </p>
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        marginTop: 2,
                      }}
                    >
                      <button
                        disabled={commentInput.length === 0}
                        style={{ border: "none", background: "none" }}
                        onClick={handleSendComment}
                      >
                        <VscSend
                          size={25}
                          color={commentInput.length === 0 ? "gray" : "black"}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            ) : null} */}
            {isReCaptchaV2ModalOpen ? (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent background
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 1000,
                }}
              >
                {isSpam ? (
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "20px",
                      borderRadius: "10px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 15,
                        fontWeight: "500",
                      }}
                    >
                      Opps..
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: "400",
                      }}
                    >
                      Your activity has been flagged as spam
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: "400",
                      }}
                    ></p>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: "400",
                      }}
                    >
                      Please verify to continue
                    </p>
                    <ReCAPTCHA
                      sitekey={"6Lfhf4QqAAAAADEwFmpMBNuG00M4KqDbr2YoqqNx"}
                      onChange={handleToken}
                    />
                  </div>
                ) : isSelling ? (
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "20px",
                      borderRadius: "10px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 15,
                        fontWeight: "500",
                      }}
                    >
                      Warning
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: "400",
                      }}
                    >
                      Your activity has been flagged for promoting illegal
                      content.
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: "400",
                      }}
                    ></p>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 11,
                        fontWeight: "400",
                      }}
                    >
                      If this is a mistake, please complete the verification to
                      continue
                    </p>
                    <ReCAPTCHA
                      sitekey={"6Lfhf4QqAAAAADEwFmpMBNuG00M4KqDbr2YoqqNx"}
                      onChange={handleToken}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            <div
              style={{
                display: "flex",
                width: "100%",
                backgroundColor: "#c7c7c7",
              }}
            >
              <div
                style={{
                  width: "35%",
                  margin: "0 auto",
                  display: "flex",
                  flexDirection: "column",
                  height: "100vh",
                  justifyContent: "space-between",
                  backgroundColor: "#fcf0f8",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    overflowY: "scroll",
                    paddingTop: "20px",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                  }}
                  ref={chatContainerRef}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      marginLeft: 20,
                    }}
                  >
                    <img
                      src={require("../mainlogo.png")}
                      alt="Logo"
                      style={{
                        borderRadius: "100%",
                        height: "25px",
                        marginRight: "10px",
                      }}
                    />
                    <p style={{ color: "#004AAD", fontSize: 15 }}>
                      <strong>ChatKOOL</strong>
                    </p>
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "15px",
                        textAlign: "left",
                        margin: 0,
                        padding: 0,
                        fontWeight: "300",
                      }}
                    >
                      You are now chatting with{" "}
                      {strangerDisplayName === null ? "a" : null}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      {strangerUniversity === "I prefer not to disclose" ||
                      strangerUniversity === null ? null : (
                        <img
                          style={{ height: 50 }}
                          src={require(`../assets/universities/${strangerUniversity}.png`)}
                          alt={strangerUniversity}
                        />
                      )}
                      {strangerDisplayName ? (
                        <p
                          style={{
                            fontSize: "20px",
                            margin: 0,
                            padding: 0,
                            marginLeft: 10,
                          }}
                        >
                          {strangerDisplayName}
                        </p>
                      ) : (
                        <p
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            color: "#004AAD",
                            margin: 0,
                            padding: 0,
                            marginLeft: 10,
                          }}
                        >
                          Stranger
                        </p>
                      )}
                    </div>
                  </div>
                  <ul
                    style={{
                      listStyleType: "none",
                      padding: 0,
                    }}
                  >
                    {messages.map((message) => (
                      <li
                        key={message.timestamp}
                        style={{
                          textAlign:
                            message.sender === user.uid ? "right" : "left",
                          padding: "5px",
                        }}
                      >
                        <span
                          style={{
                            backgroundColor:
                              message.sender === user.uid
                                ? "#004AAD"
                                : "lightgray",
                            color:
                              message.sender === user.uid ? "white" : "black",
                            borderRadius: "8px",
                            padding: "8px",
                            display: "inline-block",
                            textAlign: "left",
                            maxWidth: "70%",
                            wordWrap: "break-word",
                          }}
                        >
                          {message.text}
                        </span>
                      </li>
                    ))}
                    {isStrangerTyping ? (
                      <div
                        style={{
                          backgroundColor: "lightgray",
                          display: "inline-block",
                          borderRadius: 8,
                          paddingRight: 5,
                          paddingLeft: 5,
                          marginLeft: 5,
                          marginTop: 2,
                        }}
                      >
                        <ReactLoading
                          type="bubbles"
                          color="#808080"
                          height={33}
                          width={33}
                        />
                      </div>
                    ) : null}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {chatSessionEnded && chatSessionID ? (
                        <p
                          style={{
                            fontSize: 15,
                            color: "#FF66C4",
                            margin: 0,
                            paddingBottom: 10,
                            fontWeight: "500",
                          }}
                        >
                          {strangerDisplayName
                            ? strangerDisplayName
                            : "Stranger"}{" "}
                          left the chat
                        </p>
                      ) : null}
                      {chatSessionForcelyEnded ? (
                        <p
                          style={{
                            fontSize: 14,
                            color: "#FF66C4",
                            margin: 0,
                            paddingBottom: 10,
                          }}
                        >
                          <strong>You ended the chat</strong>
                        </p>
                      ) : null}
                    </div>
                  </ul>
                </div>
                <Announcement
                  text={announcementDATA ? announcementDATA.announcement : null}
                  onHowToUseClick={handleHowToUseClick}
                  onCommentsClick={handleCommentsClick}
                  endTime={
                    announcementDATA ? announcementDATA.duration.end : null
                  }
                />
                {chatSessionEnded || chatSessionForcelyEnded ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "10px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <button
                        onClick={() => {
                          // if (clickCount > 3) {
                          //   okayNextWithRecaptcha();
                          // } else {
                          //   okayNext();
                          // }
                          okayNext();
                        }}
                        style={{
                          marginRight: 5,
                          flex: 1,
                          height: "40px",
                          borderRadius: "8px",
                          backgroundColor: "#FF66C4",
                          color: "white",
                          borderWidth: 0,
                        }}
                      >
                        <strong>Okay, next</strong>
                      </button>
                      <button
                        onClick={() => {
                          setTheCurrentPage("MainMenu");
                        }}
                        style={{
                          marginLeft: 5,
                          flex: 1,
                          height: "40px",
                          borderRadius: "8px",
                          backgroundColor: "#004AAD",
                          color: "white",
                          borderWidth: 0,
                        }}
                      >
                        <strong>Main Menu</strong>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: 10,
                      marginRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      {showAreYouSure ? (
                        <button
                          onClick={endChat}
                          style={{
                            flex: 0.1,
                            height: "40px",
                            border: "1px solid #ccc",
                            marginRight: "5px",
                            borderRadius: 8,
                          }}
                        >
                          <strong>Sure?</strong>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setShowAreYouSure(true);
                          }}
                          style={{
                            flex: 0.1,
                            height: "40px",
                            backgroundColor: "white",
                            border: "1px solid #ccc",
                            marginRight: "5px",
                            borderRadius: 8,
                          }}
                        >
                          <strong>End</strong>
                        </button>
                      )}
                      <input
                        ref={textInputRef}
                        type="text"
                        value={inputMessage}
                        onChange={(e) => {
                          if (showAreYouSure) {
                            setShowAreYouSure(false);
                          }
                          setInputMessage(e.target.value);
                        }}
                        onKeyDown={handleKeyPress}
                        placeholder="Say something"
                        style={{
                          flex: 0.7,
                          padding: "10px",
                          fontSize: "15px",
                          borderRadius: 8,
                        }}
                      />
                      <button
                        onClick={sendMessage}
                        disabled={!inputMessage.trim()}
                        style={{
                          flex: 0.2,
                          height: "40px",
                          marginLeft: "5px",
                          borderRadius: 8,
                        }}
                      >
                        <strong>Send</strong>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )
      ) : (
        <div
          style={{
            height: isMobile ? "86vh" : "90vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* HAS POSTER DISPLAY */}
          {posterDATA ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Image element with onLoad to detect when it's fully loaded */}
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  backgroundColor: "black",
                }}
              >
                <img
                  src={posterDATA.url}
                  alt="User Poster"
                  style={{
                    width: "100%",
                    minHeight: 200,
                    maxHeight: 320,
                    display: "block",
                    objectFit: "contain", // Ensures image maintains aspect ratio
                  }}
                  onLoad={() => {
                    setIsImageLoaded(true);
                    // console.log("image loaded successfully");
                  }} // Set image loaded when fully loaded
                />
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  backgroundColor: "white",
                }}
              ></div>
            </div>
          )}

          {/* HALF */}
          {/* finding a stranger */}
          <div
            style={{
              // border: "1px solid black",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ReactLoading
                type="bars"
                color="#FF66C4"
                height={50}
                width={50}
              />
            </div>
            <p
              style={{
                fontSize: 13,
                fontWeight: "bold",
                color: "#000",
                marginBottom: 0,
                marginTop: 0,
              }}
            >
              Finding a stranger...
            </p>
            <p
              style={{
                fontSize: 10,
                color: "gray",
                marginTop: 5,
                marginBottom: 0,
                fontWeight: "400",
              }}
            >
              If this takes over a minute, please try refreshing the page
            </p>
          </div>
          {/* Only render the rest of the content if the image has loaded */}
          {/* <div
            style={{
              paddingBottom: 20,
              marginTop: 50,
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            <p
              style={{
                fontSize: 11,
                fontWeight: "500",
                textAlign: "center",
                marginBottom: 5,
              }}
            >
              Got something to share or ask?
            </p>

            <p
              style={{
                fontSize: 11,
                textAlign: "center",
                margin: 0,
                marginBottom: 10,
                fontWeight: "300",
              }}
            >
              Try our paid loudspeaker feature
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
                marginBottom: 0,
              }}
            >
              <HiSpeakerphone
                size={20}
                color={"#292929"}
                style={{ margin: 0, padding: 0 }}
              />
            </div>
            <p
              style={{
                fontSize: 13,
                textAlign: "center",
                marginTop: 5,
                marginBottom: 5,
                fontWeight: "bold",
              }}
            >
              Loudspeaker
            </p>
            <p
              style={{
                fontSize: 9,
                color: "gray",
                marginTop: 5,
                marginBottom: 5,
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              Visible to everyone, in real-time, even those already in a chat!
            </p>
            <p
              style={{
                fontSize: 11,
                color: "black",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: 5,
              }}
            >
              Contact us!
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FaTelegram size={13} color={"#229ED9"} />
              <p
                style={{
                  margin: 0,
                  marginLeft: 5,
                  fontSize: 9,
                }}
              >
                @officialchatkool
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 5,
              }}
            >
              <FaFacebook size={13} color={"blue"} />
              <p
                style={{
                  margin: 0,
                  marginLeft: 5,
                  fontSize: 9,
                }}
              >
                @officialchatkool
              </p>
            </div>
          </div> */}
          {/* ADS CONTAINER */}
          <div
            style={{
              // border: "1px solid black",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <ins
              className="adsbygoogle"
              style={{ width: 300, display: "block", objectFit: "contain" }}
              data-ad-client="ca-pub-6592285329267866"
              data-ad-slot="9256493829"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatSession;
