import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { db } from "../firebase";
import { FaUserCircle } from "react-icons/fa";
import { BsFillEmojiLaughingFill } from "react-icons/bs";
import { FaFaceAngry } from "react-icons/fa6";
import {
  IoAddSharp,
  IoChatbox,
  IoHeart,
  IoNotificationsOutline,
} from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { IoMoonOutline, IoSunnyOutline } from "react-icons/io5";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  doc,
  where,
  query,
  serverTimestamp as firestoreTimestamp,
  updateDoc,
  onSnapshot,
  getDoc,
  addDoc,
  runTransaction,
  limit,
  setLogLevel,
  orderBy,
  startAfter,
  increment,
} from "firebase/firestore";
import ReactLoading from "react-loading";
import Navbar from "../components/Navbar";
import { RiArrowDropDownLine } from "react-icons/ri";
import ProfilePicture from "../components/ProfilePicture";
import { formatDistanceToNow } from "date-fns"; // For timestamp formatting

const Post = () => {
  const { postID } = useParams();
  const commentTextRef = useRef(null);
  const {
    user,
    displayName,
    university,
    isDarkMode,
    reactedPosts,
    reactedComments,
    setIsDarkMode,
    setTheCurrentPage,
    setReactedPosts,
    setReactedComments,
  } = UserAuth();
  const [isMobile, setIsMobile] = useState(false);
  const [postDATA, setPostDATA] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [commentText, setCommentText] = useState("");
  const textRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [showCommentContainer, setShowCommentContainer] = useState(false);
  const [refreshPost, setRefreshPost] = useState(false);
  const [commentsDATA, setCommentsDATA] = useState([]);
  const [commentIDs, setCommentIDs] = useState([]);

  const hasReaction = (postID, reaction) => {
    return reactedPosts.some(
      (reactionObj) =>
        reactionObj.postID === postID && reactionObj.reaction === reaction
    );
  };
  const hasCommentReaction = (commentID, reaction) => {
    return reactedComments.some(
      (reactionObj) =>
        reactionObj.commentID === commentID && reactionObj.reaction === reaction
    );
  };
  const getButtonColor = (id, reaction, isDarkMode, postOrComment) => {
    const isSelected =
      postOrComment === "post"
        ? hasReaction(id, reaction)
        : postOrComment === "comment"
        ? hasCommentReaction(id, reaction)
        : null;

    if (isDarkMode) {
      // Dark mode colors
      return isSelected
        ? reaction === "love"
          ? "#F25268"
          : reaction === "laugh"
          ? "#FFD972"
          : "#FD776C" // Original angry color in dark mode
        : reaction === "love"
        ? "#F25268B3"
        : reaction === "laugh"
        ? "#FFD972B3"
        : "#FD776CB3"; // Angry with opacity in dark mode
    } else {
      // Light mode colors
      return isSelected
        ? reaction === "love"
          ? "#F25268"
          : reaction === "laugh"
          ? "#F4C542" // Darker yellow for laugh in light mode
          : "#bd4037" // Dark maroon for angry in light mode
        : reaction === "love"
        ? "#F25268B3"
        : reaction === "laugh"
        ? "rgba(244, 197, 66, 0.8)" // Transparent darker yellow for laugh
        : "#bd4037B3"; // Transparent maroon for angry in light mode
    }
  };

  const getTextColor = (id, reaction, postOrComment) => {
    if (postOrComment === "post") {
      return hasReaction(id, reaction)
        ? getButtonColor(id, reaction, isDarkMode)
        : isDarkMode
        ? "white"
        : "gray";
    } else if (postOrComment === "comment") {
      return hasCommentReaction(id, reaction)
        ? getButtonColor(id, reaction, isDarkMode)
        : isDarkMode
        ? "white"
        : "gray";
    }
  };

  const getFontWeight = (id, reaction, postOrComment) => {
    if (postOrComment === "post") {
      return hasReaction(id, reaction) ? "bold" : "400";
    } else if (postOrComment === "comment") {
      return hasCommentReaction(id, reaction) ? "bold" : "400";
    }
  };

  const getIconSize = (id, reaction, postOrComment) => {
    if (postOrComment === "post") {
      if (reaction === "love") {
        return hasReaction(id, reaction) ? 25 : 18; // love reaction: 22 when selected, otherwise 20
      }
      return hasReaction(id, reaction) ? 22 : 15; // other reactions: 20 when selected, otherwise 17
    } else if (postOrComment === "comment") {
      if (reaction === "love") {
        return hasCommentReaction(id, reaction) ? 25 : 18; // love reaction: 22 when selected, otherwise 20
      }
      return hasCommentReaction(id, reaction) ? 22 : 15; // other reactions: 20 when selected, otherwise 17
    }
  };

  const handleInput = (e) => {
    const inputValue = e.target.value;
    setCommentText(inputValue);

    // Adjust height based on content
    const textarea = commentTextRef.current;
    textarea.style.height = "auto"; // Reset height to calculate the new height
    const newHeight = Math.min(
      Math.max(textarea.scrollHeight, 50), // Min height
      450 // Max height
    );
    textarea.style.height = `${newHeight}px`;
  };

  const formatTimeAgo = (date) => {
    const parsedDate = date.toDate ? date.toDate() : new Date(date); // Convert Firestore Timestamp to Date
    if (isNaN(parsedDate.getTime())) {
      return "Invalid date"; // Fallback for invalid dates
    }

    const now = Date.now();
    const diffInSeconds = Math.floor((now - parsedDate.getTime()) / 1000);

    if (diffInSeconds < 60) {
      return "now";
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes}m`;
    } else {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours}h`;
    }
  };

  useEffect(() => {
    if (showCommentContainer) {
      const textElement = textRef.current;
      if (textElement) {
        const hasOverflow = textElement.scrollHeight > textElement.clientHeight;
        setIsOverflowing(hasOverflow);
      }
    }
  }, [showCommentContainer]);

  useEffect(() => {
    // Automatically focus the textarea when the component mounts
    if (showCommentContainer) {
      if (commentTextRef.current) {
        commentTextRef.current.focus();
      }
    }
  }, [showCommentContainer]);

  // asddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const postDoc = await getDoc(doc(db, "Posts", postID)); // Fetch the post document
        if (postDoc.exists()) {
          setPostDATA({ id: postDoc.id, ...postDoc.data() }); // Set the post data, including the document ID
        } else {
          setError("Post not found.");
        }
      } catch (err) {
        setError("Failed to fetch the post. Please try again later.");
        console.error(err);
      } finally {
        setIsLoading(false); // Stop the loading indicator
      }
    };

    fetchPost();
  }, [postID, refreshPost]); // Refresh post data when postID or refreshPost changes

  useEffect(() => {
    if (postDATA?.commentIDs?.length) {
      setCommentIDs(postDATA.commentIDs); // Set the comment IDs from the post data
    } else {
      setCommentIDs([]); // Reset comment IDs if not available
    }
  }, [postDATA]);

  useEffect(() => {
    console.log(reactedComments);
  }, [reactedComments]);

  useEffect(() => {
    const fetchCommentsAndReactions = async () => {
      if (!commentIDs || commentIDs.length === 0) return;

      try {
        // Fetch comments based on commentIDs
        const fetchedComments = await Promise.all(
          commentIDs.map(async (id) => {
            const docRef = doc(db, "Comments", id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
              return { ...docSnap.data(), id };
            } else {
              console.warn(`No comment found for ID: ${id}`);
              return null;
            }
          })
        );

        // Filter out null results (in case some IDs didn't return data)
        const validComments = fetchedComments.filter(
          (comment) => comment !== null
        );

        // Fetch persisted reactions from localStorage
        const persistedReactions =
          JSON.parse(localStorage.getItem("reactedComments")) || [];

        // Initialize or merge reactions for comments based on fetched comment data
        const initialReactedComments = validComments.map((comment) => {
          const userReaction = persistedReactions.find(
            (reaction) => reaction.commentID === comment.id
          );

          return {
            commentID: comment.id,
            reaction: userReaction ? userReaction.reaction : null, // Persisted reaction or null
            loveQty: userReaction?.loveQty || 0,
            laughQty: userReaction?.laughQty || 0,
            angryQty: userReaction?.angryQty || 0,
            ...comment, // Include comment data (text, user info, etc.)
          };
        });

        setCommentsDATA(validComments); // Update comments state
        setReactedComments(initialReactedComments); // Update reactions state
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    fetchCommentsAndReactions();
  }, [commentIDs]); // Trigger when commentIDs change

  useEffect(() => {
    localStorage.setItem("reactedComments", JSON.stringify(reactedComments));
  }, [reactedComments]);

  const ReactionButton = ({ postID, reaction, icon: Icon, qty }) => (
    <button
      style={{
        background: "none",
        border: "none",
        padding: 0,
        cursor: "pointer",
        marginRight: 5,
      }}
      onClick={() => handleReactionClick(postID, reaction)}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon
          size={getIconSize(postID, reaction, "post")}
          color={getButtonColor(postID, reaction, isDarkMode, "post")}
        />
        <p
          style={{
            margin: 0,
            fontSize: 11,
            fontWeight: getFontWeight(postID, reaction, "post"),
            marginLeft: 2,
            color: getTextColor(postID, reaction, "post"),
            userSelect: "none",
          }}
        >
          {qty}
        </p>
      </div>
    </button>
  );

  const CommentReactionButton = ({ commentID, reaction, icon: Icon, qty }) => {
    const handleCommentReactionClick = async () => {
      try {
        const currentComment = reactedComments.find(
          (c) => c.commentID === commentID
        );
        if (!currentComment) {
          console.error("Comment not found in reactedComments.");
          return;
        }

        const previousReaction = currentComment.reaction;

        setReactedComments((prevReactedComments) =>
          prevReactedComments.map((comment) => {
            if (comment.commentID === commentID) {
              if (comment.reaction === reaction) {
                comment[`${reaction}Qty`] -= 1;
                comment.reaction = null;
              } else {
                if (previousReaction) {
                  comment[`${previousReaction}Qty`] -= 1;
                }
                comment[`${reaction}Qty`] += 1;
                comment.reaction = reaction;
              }
            }
            return comment;
          })
        );

        const updateData = {};
        if (previousReaction === reaction) {
          updateData[`${reaction}Qty`] = increment(-1);
        } else {
          if (previousReaction) {
            updateData[`${previousReaction}Qty`] = increment(-1);
          }
          updateData[`${reaction}Qty`] = increment(1);
        }

        const commentRef = doc(db, "Comments", commentID);
        await updateDoc(commentRef, updateData);
      } catch (error) {
        console.error("Error updating comment reaction:", error);
      }
    };
    return (
      <button
        style={{
          background: "none",
          border: "none",
          padding: 0,
          cursor: "pointer",
          marginRight: 5,
        }}
        onClick={handleCommentReactionClick}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon
            size={getIconSize(commentID, reaction, "comment")}
            color={getButtonColor(commentID, reaction, isDarkMode, "comment")}
          />
          <p
            style={{
              margin: 0,
              fontSize: 11,
              fontWeight: getFontWeight(commentID, reaction, "comment"),
              marginLeft: 2,
              color: getTextColor(commentID, reaction, "comment"),
              userSelect: "none",
            }}
          >
            {qty}
          </p>
        </div>
      </button>
    );
  };

  const handleReactionClick = async (postID, reaction) => {
    console.log("this is being called");
    try {
      // Find the current post's reaction in the local state
      const currentPost = reactedPosts.find((post) => post.postID === postID);

      if (!currentPost) {
        console.error("Post not found in reactedPosts.");
        return;
      }

      const previousReaction = currentPost.reaction;

      // Update local state for immediate UI feedback
      setReactedPosts((prevReactedPosts) =>
        prevReactedPosts.map((post) => {
          if (post.postID === postID) {
            if (post.reaction === reaction) {
              post[`${reaction}Qty`] -= 1;
              post.reaction = null;
            } else {
              if (previousReaction) {
                post[`${previousReaction}Qty`] -= 1;
              }
              post[`${reaction}Qty`] += 1;
              post.reaction = reaction;
            }
          }
          return post;
        })
      );

      // Prepare Firestore update object
      const updateData = {};

      if (previousReaction === reaction) {
        // User removed the same reaction
        updateData[`${reaction}Qty`] = increment(-1);
      } else {
        // User switched reactions or added a new one
        if (previousReaction) {
          updateData[`${previousReaction}Qty`] = increment(-1);
        }
        updateData[`${reaction}Qty`] = increment(1);
      }

      // Update Firestore with the changes
      const postRef = doc(db, "Posts", postID);
      await updateDoc(postRef, updateData);
    } catch (error) {
      console.error("Error updating reaction:", error);
    }
  };

  const uploadComment = async () => {
    try {
      // Create the new comment with default reaction quantities
      const newCommentRef = await addDoc(collection(db, "Comments"), {
        user: {
          userID: user.uid,
          displayName: displayName,
          university: university,
        },
        comment: commentText,
        timestamp: firestoreTimestamp(),
        loveQty: 0,
        laughQty: 0,
        angryQty: 0,
      });

      const postRef = doc(db, "Posts", postID);
      await updateDoc(postRef, {
        commentIDs: arrayUnion(newCommentRef.id), // Ensure this field is an
      });

      // Close the comment container and reset text
      setShowCommentContainer(false);
      setCommentText("");

      // Trigger re-fetch of the post data
      setRefreshPost((prev) => !prev);
    } catch (error) {
      console.error("Error adding comment:", error);
      // alert("Failed to add comment. Please try again.");
    }
  };

  const ReactionButtonsGroup = ({ postID, loveQty, laughQty, angryQty }) => {
    // Define the reactions as objects
    const reactions = [
      { reaction: "love", icon: IoHeart, qty: loveQty },
      { reaction: "laugh", icon: BsFillEmojiLaughingFill, qty: laughQty },
      { reaction: "angry", icon: FaFaceAngry, qty: angryQty },
    ];

    // Sort reactions by quantity in descending order
    const sortedReactions = reactions.sort((a, b) => b.qty - a.qty);

    // Render sorted reaction buttons
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {sortedReactions.map(({ reaction, icon, qty }) => (
          <ReactionButton
            key={reaction}
            postID={postID}
            reaction={reaction}
            icon={icon}
            qty={qty}
          />
        ))}
      </div>
    );
  };
  const reactedPost = reactedPosts.find((r) => r.postID === postID);

  const CommentReactionButtonsGroup = ({
    commentID,
    loveQty,
    laughQty,
    angryQty,
  }) => {
    const reactions = [
      { reaction: "love", icon: IoHeart, qty: loveQty },
      { reaction: "laugh", icon: BsFillEmojiLaughingFill, qty: laughQty },
      { reaction: "angry", icon: FaFaceAngry, qty: angryQty },
    ];

    const sortedReactions = reactions.sort((a, b) => b.qty - a.qty);

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {sortedReactions.map(({ reaction, icon, qty }) => (
          <CommentReactionButton
            key={reaction}
            commentID={commentID}
            reaction={reaction}
            icon={icon}
            qty={qty}
          />
        ))}
      </div>
    );
  };

  const ActualPost = ({
    postID,
    user,
    post,
    loveQty,
    laughQty,
    angryQty,
    commentQty,
    timestamp,
  }) => {
    return (
      <div style={{ marginBottom: 25, marginTop: 50 }}>
        {/* Upper section: contains the user's name and university */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex" }}>
            <ProfilePicture university={user.university} type={"actualpost"} />
            <div>
              <p
                style={{
                  margin: 0,
                  fontSize: 15,
                  fontWeight: "bold",
                  userSelect: "none",
                  color: isDarkMode ? "white" : "black",
                }}
              >
                {user.displayName}
              </p>
              <p
                style={{
                  margin: 0,
                  marginTop: 3,
                  fontSize: 11,
                  fontWeight: "500",
                  color: isDarkMode ? "lightgray" : "gray",
                  userSelect: "none",
                }}
              >
                {formatTimeAgo(timestamp)}
              </p>
            </div>
          </div>
        </div>

        {/* Middle section: contains the post */}
        <button
          onClick={() => {}}
          style={{
            border: "none",
            background: "none",
            padding: 0, // Remove padding from the button
            margin: 0, // Remove margin from the button
            display: "block", // Make the button behave like a block element
            textAlign: "left", // Ensure text aligns properly
            width: "100%", // Optional, if you want the button to span the full width
          }}
        >
          <p
            style={{
              fontSize: 16,
              fontWeight: "600",
              margin: 0,
              marginTop: 10,
              color: isDarkMode ? "white" : "black",
              cursor: "text",
              userSelect: "none",
            }}
          >
            {post.title}
          </p>
          <p
            style={{
              color: isDarkMode ? "lightgray" : "#4a4a4a",
              fontSize: 13,
              marginBottom: 0,
              marginTop: 7,
              whiteSpace: "pre-wrap",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              cursor: "text",
              fontFamily: "sans-serif",
              userSelect: "none",
              WebkitTapHighlightColor: "transparent",
            }}
            // onClick={() => setTheCurrentPage("EachPost")}
          >
            {post.text}
          </p>
        </button>

        {/* Lower section: contains the number of likes and comments */}
        <div style={{ marginTop: 5, display: "flex" }}>
          <ReactionButtonsGroup
            postID={postID}
            loveQty={loveQty}
            laughQty={laughQty}
            angryQty={angryQty}
          />
          <button
            style={{
              background: "none",
              border: "none",
              padding: 0,
              cursor: "pointer",
            }}
            onClick={() => {}}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IoChatbox size={17} color={isDarkMode ? "lightgray" : "gray"} />
              <p
                style={{
                  margin: 0,
                  fontSize: 12,
                  fontWeight: "400",
                  marginLeft: 2,
                  color: isDarkMode ? "white" : "gray",
                  userSelect: "none",
                }}
              >
                {commentQty}
              </p>
            </div>
          </button>
        </div>
      </div>
    );
  };

  const LoadingScreen = () => {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "black",
          opacity: 0.7,
          zIndex: 9999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactLoading type="spin" color="#FF66C4" height={40} width={40} />
      </div>
    );
  };

  const Sorter = () => {
    const [currentSort, setCurrentSort] = useState("Best");
    const [showModal, setShowModal] = useState(false);

    const sortingOptions = ["Best", "Hot", "New", "Top", "Rising"];

    const handleSortSelect = (option) => {
      setCurrentSort(option);
      setShowModal(false);
    };

    // Inline styles object
    const sortingStyle = {
      sorterButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
        color: isDarkMode ? "white" : "black",
        fontWeight: "500",
        marginLeft: 0,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 0,
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: 15,
        marginBottom: 10,
        outline: "none",
        boxShadow: "none",
        userSelect: "none",
        WebkitTapHighlightColor: "transparent",
      },
      modalOverlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      modalContent: {
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "8px",
        textAlign: "center",
        width: "300px",
      },
      sortOption: {
        backgroundColor: "white",
        border: "none",
        padding: "10px",
        width: "100%",
        textAlign: "left",
        cursor: "pointer",
        fontSize: "16px",
      },
      sortOptionHover: {
        backgroundColor: "white",
      },
      sortOptionSelected: {
        fontWeight: "bold",
        color: "#0079d3",
      },
      closeModal: {
        marginTop: "10px",
        padding: "8px 12px",
        backgroundColor: "#0079d3",
        color: "white",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
      },
    };

    return (
      <div>
        {/* Sorter Button */}
        <button
          onClick={() => setShowModal(!showModal)}
          style={sortingStyle.sorterButton}
        >
          {currentSort}{" "}
          <RiArrowDropDownLine style={{ marginLeft: 5 }} size={25} />
        </button>

        {/* Modal for Sorting Options */}
        {showModal && (
          <div style={sortingStyle.modalOverlay}>
            <div style={sortingStyle.modalContent}>
              <h3>Sort by</h3>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  backgroundColor: "white",
                }}
              >
                {sortingOptions.map((option) => (
                  <li key={option}>
                    <button
                      onClick={() => handleSortSelect(option)}
                      style={{
                        ...sortingStyle.sortOption,
                        ...(option === currentSort
                          ? sortingStyle.sortOptionSelected
                          : {}),
                        ...(option !== currentSort
                          ? sortingStyle.sortOptionHover
                          : {}),
                      }}
                    >
                      {option}
                    </button>
                  </li>
                ))}
              </ul>
              <button
                onClick={() => setShowModal(false)}
                style={sortingStyle.closeModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const CommentsSection = ({ commentIDs }) => {
    if (commentsDATA.length === 0) {
      return (
        <p
          style={{
            textAlign: "center",
            fontSize: 13,
            fontWeight: "300",
            color: "gray",
            marginTop: 20,
          }}
        >
          No comments yet
        </p>
      );
    }

    return (
      <div>
        {commentsDATA.map((comment) => {
          const reactedComment = reactedComments.find(
            (r) => r.commentID === comment.id
          );

          return (
            <EachComment
              key={comment.id}
              commentID={comment.id}
              user={comment.user}
              comment={comment.comment}
              loveQty={
                reactedComment ? reactedComment.loveQty : comment.loveQty
              }
              laughQty={
                reactedComment ? reactedComment.laughQty : comment.laughQty
              }
              angryQty={
                reactedComment ? reactedComment.angryQty : comment.angryQty
              }
              timestamp={comment.timestamp}
            />
          );
        })}
      </div>
    );
  };

  const EachComment = ({
    commentID,
    user,
    comment,
    loveQty,
    laughQty,
    angryQty,
    timestamp,
  }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isTruncated, setIsTruncated] = useState(false);
    const contentRef = useRef(null);

    const maxLines = 3; // Number of lines to show before truncating

    useEffect(() => {
      // Check if the text is truncated
      const checkIfTruncated = () => {
        if (contentRef.current) {
          const element = contentRef.current;
          setIsTruncated(element.scrollHeight > element.clientHeight);
        }
      };

      checkIfTruncated();
      window.addEventListener("resize", checkIfTruncated);
      return () => window.removeEventListener("resize", checkIfTruncated);
    }, [comment, isExpanded]);

    const handleToggleExpand = () => {
      setIsExpanded((prev) => !prev);
    };

    return (
      <div style={{ marginBottom: 10 }}>
        {/* Upper section: user's name and timestamp */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", flex: 1 }}>
            <div style={{ marginTop: 5 }}>
              <ProfilePicture university={user.university} />
            </div>
            <div
              style={{
                backgroundColor: isDarkMode ? "#35383d" : "#f0f0f0",
                display: "flex",
                flexDirection: "column",
                padding: 10,
                borderRadius: 15,
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: 13,
                  fontWeight: "bold",
                  userSelect: "none",
                  color: isDarkMode ? "white" : "black",
                }}
              >
                {user.displayName}
              </p>
              <p
                ref={contentRef}
                style={{
                  color: isDarkMode ? "lightgray" : "black",
                  fontSize: 14,
                  marginBottom: 0,
                  marginTop: 7,
                  whiteSpace: "pre-wrap",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  WebkitLineClamp: isExpanded ? "unset" : maxLines,
                  cursor: "text",
                  fontFamily: "sans-serif",
                  userSelect: "none",
                  WebkitTapHighlightColor: "transparent",
                }}
              >
                {comment}
              </p>
              {!isExpanded && isTruncated && (
                <p
                  style={{
                    color: isDarkMode ? "white" : "black",
                    fontWeight: "bolder",
                    cursor: "pointer",
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 0,
                    userSelect: "none",
                  }}
                  onClick={handleToggleExpand}
                >
                  Show more
                </p>
              )}
            </div>
          </div>
        </div>

        {/* Lower section: reactions */}
        <div
          style={{
            marginTop: 5,
            display: "flex",
            marginLeft: 30,
            alignItems: "center",
          }}
        >
          <p
            style={{
              margin: 0,
              marginLeft: 5,
              fontSize: 11,
              fontWeight: "500",
              color: isDarkMode ? "gray" : "gray",
              userSelect: "none",
              marginRight: 5,
            }}
          >
            {formatTimeAgo(timestamp)}
          </p>
          <CommentReactionButtonsGroup
            commentID={commentID}
            loveQty={loveQty}
            laughQty={laughQty}
            angryQty={angryQty}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {/* for mobile view */}
          {isMobile ? (
            <div
              style={{
                padding: 20,
                marginBottom: 30,
                backgroundColor: isDarkMode ? "#282c34" : "#fffcfc",
                minHeight: "90vh", // Ensure the container covers the full viewport height
              }}
            >
              <div
                style={{
                  maxWidth: 800,
                  margin: "0 auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: isDarkMode ? "black" : "white",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 100, // Ensures it appears above other content
                    padding: "10px", // Optional for spacing
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={require("../mainlogo.png")}
                      alt="Logo"
                      style={{
                        borderRadius: "100%",
                        height: "25px",
                        marginRight: "5px",
                      }}
                    />

                    <p
                      style={{
                        marginBottom: 0,
                        fontSize: 15,
                        marginTop: 0,
                        marginLeft: 5,
                        color: isDarkMode ? "white" : "#004AAD",
                        fontWeight: "bold",
                      }}
                    >
                      DailyThoughts
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* Moon Button - Dark Mode */}
                    {isDarkMode ? (
                      <button
                        style={{
                          display: "flex",
                          background: "none",
                          border: "none",
                          padding: 0,
                          margin: 0,
                          width: "auto",
                          height: "auto",
                          appearance: "none",
                          cursor: "pointer",
                          marginRight: 5,
                        }}
                        onClick={() => setIsDarkMode(false)} // Switch to light mode
                      >
                        <IoSunnyOutline size={25} color={"white"} />
                      </button>
                    ) : (
                      // Sun Button - Light Mode
                      <button
                        style={{
                          display: "flex",
                          background: "none",
                          border: "none",
                          padding: 0,
                          margin: 0,
                          width: "auto",
                          height: "auto",
                          appearance: "none",
                          cursor: "pointer",
                          marginRight: 5,
                        }}
                        onClick={() => setIsDarkMode(true)} // Switch to dark mode
                      >
                        <IoMoonOutline size={25} color={"black"} />
                      </button>
                    )}

                    {/* Notification Button */}
                    <button
                      style={{
                        display: "flex",
                        background: "none",
                        border: "none",
                        padding: 0,
                        margin: 0,
                        width: "auto",
                        height: "auto",
                        appearance: "none",
                        cursor: "pointer",
                      }}
                    >
                      <IoNotificationsOutline
                        size={25}
                        color={isDarkMode ? "white" : "black"}
                      />
                      <p
                        style={{
                          position: "absolute",
                          right: 11,
                          backgroundColor: "#ff66c4",
                          borderRadius: 100,
                          margin: 0,
                          paddingLeft: 3,
                          paddingRight: 3,
                          paddingTop: 1.5,
                          paddingBottom: 1.5,
                          fontSize: 11,
                          fontWeight: "bolder",
                          color: "white",
                        }}
                      >
                        1
                      </p>
                    </button>
                  </div>
                </div>
                {showCommentContainer ? (
                  <div
                    style={{
                      paddingBottom: 40,
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: isDarkMode ? "#282c34" : "#fffcfc",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: isDarkMode ? "#282c34" : "#fffcfc",
                        paddingTop: 60,
                      }}
                    >
                      <button
                        style={{
                          display: "flex",
                          border: "none",
                          background: "none",
                          padding: 0,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowCommentContainer(false);
                          setCommentText("");
                        }}
                      >
                        <IoMdClose
                          size={25}
                          color={isDarkMode ? "white" : "black"}
                        />
                      </button>

                      <button
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          border: "none",
                          background: "none",
                          padding: 0,
                        }}
                        onClick={async () => {
                          await uploadComment();
                        }}
                      >
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            backgroundColor: "#ff66c4",
                            color: "white",
                            border: "none",
                            borderRadius: 10,
                            padding: "10px",
                            fontSize: 11,
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          Comment
                        </p>
                      </button>
                    </div>
                    <p
                      style={{
                        marginTop: 7.5,
                        marginBottom: 0,
                        border: "none",
                        fontSize: 16,
                        fontWeight: "bold",
                        outline: "none",
                        color: isDarkMode ? "white" : "black",
                      }}
                    >
                      {postDATA.post.title && postDATA.post.title}
                    </p>
                    <p
                      ref={textRef}
                      style={{
                        marginTop: 7.5,
                        border: "none",
                        fontSize: 13,
                        outline: "none",
                        color: isDarkMode ? "lightgray" : "black",
                        whiteSpace: "pre-wrap",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        fontFamily: "sans-serif",
                        cursor: "text",
                        userSelect: "none",
                        WebkitTapHighlightColor: "transparent",
                        marginBottom: 5,
                        maxHeight: "63px", // Add a max height
                        overflowY: "auto", // Add scrolling for overflow content
                      }}
                    >
                      {postDATA.post.text && postDATA.post.text}
                    </p>
                    {isOverflowing && (
                      <p
                        style={{
                          all: "unset", // Resets all default button styles
                          margin: 0,
                          fontSize: 10,
                          fontWeight: "400",
                          color: isDarkMode ? "gray" : "gray",
                          textAlign: "center",
                          cursor: "pointer", // Makes it clear it's clickable
                          WebkitTapHighlightColor: "transparent",
                        }}
                      >
                        Scroll to see more
                      </p>
                    )}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 20,
                      }}
                    >
                      <ProfilePicture university={university} />
                      <p
                        style={{
                          fontSize: 10,
                          color: isDarkMode ? "white" : "black",
                          fontWeight: "bold",
                          margin: 0,
                          marginLeft: 0,
                        }}
                      >
                        {displayName}
                      </p>
                    </div>
                    <textarea
                      ref={commentTextRef}
                      value={commentText}
                      onChange={handleInput}
                      placeholder="Say something about it"
                      style={{
                        marginTop: 5,
                        minHeight: "50px", // Minimum height
                        maxHeight: "300px", // Maximum height constraint
                        fontSize: 14,
                        fontFamily: "inherit",
                        fontWeight: "inherit",
                        lineHeight: "inherit",
                        backgroundColor: isDarkMode ? "#282c34" : "#fffcfc",
                        outline: "none",
                        border: "0.5px solid gray",
                        borderRadius: 10,
                        resize: "none", // Prevents manual resizing
                        color: isDarkMode ? "lightgray" : "black",
                        overflowY: "auto", // Enables scrollbar if necessary
                        transition: "height 0.2s ease", // Smooth height
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 10,
                        paddingBottom: 2.5,
                      }}
                      maxLength={3000}
                    />
                  </div>
                ) : postDATA ? (
                  <div style={{ marginBottom: 30 }}>
                    <ActualPost
                      key={postID}
                      user={postDATA.user}
                      postID={postID}
                      post={postDATA.post}
                      loveQty={
                        reactedPost ? reactedPost.loveQty : postDATA.loveQty
                      }
                      laughQty={
                        reactedPost ? reactedPost.laughQty : postDATA.laughQty
                      }
                      angryQty={
                        reactedPost ? reactedPost.angryQty : postDATA.angryQty
                      }
                      commentQty={postDATA.commentIDs.length}
                      timestamp={postDATA.timestamp}
                    />
                    {commentsDATA.length === 0 ? null : (
                      <div>
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            textAlign: "center",
                            color: isDarkMode ? "white" : "black",
                          }}
                        >
                          Comments
                        </p>
                        <Sorter />
                      </div>
                    )}
                    <CommentsSection commentIDs={postDATA.commentIDs} />
                  </div>
                ) : null}
                {!showCommentContainer && (
                  <button
                    style={{
                      position: "fixed",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      padding: "15px",
                      backgroundColor: isDarkMode ? "#222" : "#f0f0f0",
                      zIndex: 100, // Ensure it floats above other content
                      display: "flex",
                      alignItems: "center",
                      border: "none", // Remove default button border
                      outline: "none", // Remove default focus outline
                      cursor: "pointer", // Indicate clickability
                      appearance: "none", // Reset default button styling for cross-browser consistency
                      WebkitTapHighlightColor: "gray",
                    }}
                    onClick={() => {
                      setShowCommentContainer(true);
                    }} // Add your onClick function here
                  >
                    <IoChatbox
                      size={17}
                      color={isDarkMode ? "lightgray" : "gray"}
                      style={{ marginLeft: 5 }}
                    />
                    <p
                      style={{
                        fontSize: 13,
                        margin: 5,
                        color: "gray",
                        marginLeft: 10,
                      }}
                    >
                      Write a comment
                    </p>
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </>
      )}
    </>
  );
};

export default Post;
