import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { db } from "../firebase";
import { FaUserCircle } from "react-icons/fa";
import { BsFillEmojiLaughingFill } from "react-icons/bs";
import { FaFaceAngry } from "react-icons/fa6";
import {
  IoAddSharp,
  IoChatbox,
  IoHeart,
  IoNotificationsOutline,
} from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { IoMoonOutline, IoSunnyOutline } from "react-icons/io5";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  arrayRemove,
  doc,
  where,
  query,
  serverTimestamp as firestoreTimestamp,
  updateDoc,
  onSnapshot,
  getDoc,
  addDoc,
  runTransaction,
  limit,
  setLogLevel,
  orderBy,
  startAfter,
  increment,
} from "firebase/firestore";
import ReactLoading from "react-loading";
import Navbar from "../components/Navbar";
import { RiArrowDropDownLine } from "react-icons/ri";
import ProfilePicture from "../components/ProfilePicture";
import { formatDistanceToNow } from "date-fns"; // For timestamp formatting

const DailyThoughts = () => {
  const {
    user,
    displayName,
    university,
    isDarkMode,
    setIsDarkMode,
    setTheCurrentPage,
    reactedPosts,
    setReactedPosts,
  } = UserAuth();
  const [isMobile, setIsMobile] = useState(false);
  const [postTitle, setPostTitle] = useState("");
  const [postText, setPostText] = useState("");
  const [postDATA, setPostDATA] = useState([]);
  const [showPostContainer, setShowPostContainer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentSort, setCurrentSort] = useState("Best");
  const [showModal, setShowModal] = useState(false);
  const sortingOptions = ["Best", "Hot", "New", "Top", "Rising"];
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);

  const dtContainerRef = useRef(null);
  const textInputRef = useRef(null);
  const postTitleRef = useRef(null);
  const postMessageRef = useRef(null);

  const navigate = useNavigate();

  const hasReaction = (postID, reaction) => {
    return reactedPosts.some(
      (reactionObj) =>
        reactionObj.postID === postID && reactionObj.reaction === reaction
    );
  };

  const getButtonColor = (postID, reaction, isDarkMode) => {
    const isSelected = hasReaction(postID, reaction);

    if (isDarkMode) {
      // Dark mode colors
      return isSelected
        ? reaction === "love"
          ? "#F25268"
          : reaction === "laugh"
          ? "#FFD972"
          : "#FD776C" // Original angry color in dark mode
        : reaction === "love"
        ? "#F25268B3"
        : reaction === "laugh"
        ? "#FFD972B3"
        : "#FD776CB3"; // Angry with opacity in dark mode
    } else {
      // Light mode colors
      return isSelected
        ? reaction === "love"
          ? "#F25268"
          : reaction === "laugh"
          ? "#F4C542" // Darker yellow for laugh in light mode
          : "#bd4037" // Dark maroon for angry in light mode
        : reaction === "love"
        ? "#F25268B3"
        : reaction === "laugh"
        ? "rgba(244, 197, 66, 0.8)" // Transparent darker yellow for laugh
        : "#bd4037B3"; // Transparent maroon for angry in light mode
    }
  };

  const getTextColor = (postID, reaction) => {
    return hasReaction(postID, reaction)
      ? getButtonColor(postID, reaction, isDarkMode)
      : isDarkMode
      ? "white"
      : "gray";
  };

  const getFontWeight = (postID, reaction) => {
    return hasReaction(postID, reaction) ? "bold" : "400";
  };

  // Function to adjust the icon size based on reaction selection
  const getIconSize = (postID, reaction) => {
    if (reaction === "love") {
      return hasReaction(postID, reaction) ? 25 : 18; // love reaction: 22 when selected, otherwise 20
    }
    return hasReaction(postID, reaction) ? 22 : 15; // other reactions: 20 when selected, otherwise 17
  };

  const ReactionButton = ({ postID, reaction, icon: Icon, qty, isReacted }) => {
    const handleReactionClick = async () => {
      try {
        const reactionField = `${reaction}ReactorIDs`;
        const reactionFields = [
          "loveReactorIDs",
          "laughReactorIDs",
          "angryReactorIDs",
        ];

        const updates = {};
        let newUserReaction = null;

        if (isReacted) {
          // User is unreacting
          updates[reactionField] = arrayRemove(user.uid);
        } else {
          // Remove from all other reactions and add the current one
          reactionFields.forEach((field) => {
            updates[field] = arrayRemove(user.uid);
          });
          updates[reactionField] = arrayUnion(user.uid);
          newUserReaction = reaction;
        }

        // Update local state
        setReactedPosts((prevReactedPosts) => {
          const updatedPosts = prevReactedPosts.filter(
            (r) => r.postID !== postID
          );
          if (newUserReaction) {
            updatedPosts.push({ postID, reaction: newUserReaction });
          }
          return updatedPosts;
        });

        // Update postDATA for immediate UI feedback
        setPostDATA((prevPosts) =>
          prevPosts.map((post) => {
            if (post.id === postID) {
              const updatedPost = { ...post };

              // Remove user from all reactors
              reactionFields.forEach((field) => {
                updatedPost[field] = updatedPost[field]?.filter(
                  (uid) => uid !== user.uid
                );
              });

              // Add user to the selected reactor
              if (newUserReaction) {
                updatedPost[reactionField] = [
                  ...(updatedPost[reactionField] || []),
                  user.uid,
                ];
              }

              return updatedPost;
            }
            return post;
          })
        );

        // Update Firestore
        await updateDoc(doc(db, "Posts", postID), updates);
      } catch (error) {
        console.error(`Error toggling reaction:`, error);
      }
    };

    return (
      <button
        style={{
          background: "none",
          border: "none",
          padding: 0,
          cursor: "pointer",
          marginRight: 5,
        }}
        onClick={handleReactionClick}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon
            size={getIconSize(postID, reaction)}
            color={getButtonColor(postID, reaction, isDarkMode)}
          />
          <p
            style={{
              margin: 0,
              fontSize: 11,
              fontWeight: getFontWeight(postID, reaction),
              marginLeft: 2,
              color: getTextColor(postID, reaction),
              userSelect: "none",
            }}
          >
            {qty}
          </p>
        </div>
      </button>
    );
  };

  const handleSortSelect = (option) => {
    setCurrentSort(option);
    setShowModal(false);
  };

  const ReactionButtonsGroup = ({
    postID,
    loveQty,
    laughQty,
    angryQty,
    userReaction,
  }) => {
    const reactions = [
      { reaction: "love", icon: IoHeart, qty: loveQty },
      { reaction: "laugh", icon: BsFillEmojiLaughingFill, qty: laughQty },
      { reaction: "angry", icon: FaFaceAngry, qty: angryQty },
    ];

    const sortedReactions = reactions.sort((a, b) => b.qty - a.qty);

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {sortedReactions.map(({ reaction, icon, qty }) => (
          <ReactionButton
            key={reaction}
            postID={postID}
            reaction={reaction}
            icon={icon}
            qty={qty}
            isReacted={userReaction === reaction}
          />
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (isReminderModalOpen) {
      // Disable scrolling on the body when the modal is open
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable scrolling when the modal is closed
      document.body.style.overflow = "auto";
    }

    // Cleanup when the component is unmounted or modal is closed
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isReminderModalOpen]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const onBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // Check if displayName and university are set
    if (!displayName || !university) {
      // Navigate to the main menu and replace the current entry in the history stack
      navigate("/", { replace: true });
    } else {
      setIsLoading(false);
    }
  }, [displayName, university, navigate]);

  const EachPost = ({
    postID,
    user,
    post,
    loveQty,
    laughQty,
    angryQty,
    userReaction,
    commentQty,
    timestamp,
  }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isTruncated, setIsTruncated] = useState(false);
    const contentRef = useRef(null);

    const maxLines = 4; // Number of lines to show before truncating

    const handleToggleExpand = () => {
      setIsExpanded((prev) => !prev);
    };

    useEffect(() => {
      // Check if the text is truncated
      const checkIfTruncated = () => {
        if (contentRef.current) {
          const element = contentRef.current;
          setIsTruncated(element.scrollHeight > element.clientHeight);
        }
      };

      checkIfTruncated();
      // Re-check on resize or content change
      window.addEventListener("resize", checkIfTruncated);
      return () => window.removeEventListener("resize", checkIfTruncated);
    }, [post, isExpanded]);

    return (
      <div style={{ marginBottom: 25 }}>
        {/* Upper section: contains the user's name and university */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
            <ProfilePicture university={user.university} />
            <p
              style={{
                margin: 0,
                fontSize: 12,
                fontWeight: "bold",
                userSelect: "none",
                color: isDarkMode ? "white" : "black",
              }}
            >
              {user.displayName}
            </p>
          </div>
          <p
            style={{
              margin: 0,
              marginLeft: 5,
              fontSize: 12,
              color: isDarkMode ? "lightgray" : "gray",
              userSelect: "none",
            }}
          >
            {formatTimeAgo(timestamp)}
          </p>
        </div>

        {/* Middle section: contains the post */}
        <button
          onClick={() => {
            navigate(`/dailythoughts/post/${postID}`);
          }}
          style={{
            border: "none",
            background: "none",
            padding: 0, // Remove padding from the button
            margin: 0, // Remove margin from the button
            display: "block", // Make the button behave like a block element
            textAlign: "left", // Ensure text aligns properly
            width: "100%", // Optional, if you want the button to span the full width
          }}
        >
          <p
            style={{
              fontSize: 16,
              fontWeight: "600",
              margin: 0,
              marginTop: 10,
              color: isDarkMode ? "white" : "black",
              cursor: "text",
              userSelect: "none",
            }}
          >
            {post.title}
          </p>
          <p
            ref={contentRef}
            style={{
              color: isDarkMode ? "lightgray" : "#4a4a4a",
              fontSize: 13,
              marginBottom: 0,
              marginTop: 7,
              whiteSpace: "pre-wrap",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: isExpanded ? "unset" : maxLines,
              cursor: "text",
              fontFamily: "sans-serif",
              userSelect: "none",
              WebkitTapHighlightColor: "transparent",
            }}
          >
            {post.text}
          </p>
        </button>
        {/* Lower section: contains the number of likes and comments */}
        {console.log(reactedPosts)}
        <div style={{ marginTop: 5, display: "flex" }}>
          <ReactionButtonsGroup
            postID={postID}
            loveQty={
              postDATA.find((post) => post.id === postID)?.loveReactorIDs
                ?.length || 0
            }
            laughQty={
              postDATA.find((post) => post.id === postID)?.laughReactorIDs
                ?.length || 0
            }
            angryQty={
              postDATA.find((post) => post.id === postID)?.angryReactorIDs
                ?.length || 0
            }
            userReaction={
              reactedPosts.find((r) => r.postID === postID)?.reaction || null
            }
          />
          <button
            style={{
              background: "none",
              border: "none",
              padding: 0,
              cursor: "pointer",
            }}
            onClick={() => {}}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IoChatbox size={17} color={isDarkMode ? "lightgray" : "gray"} />
              <p
                style={{
                  margin: 0,
                  fontSize: 12,
                  fontWeight: "400",
                  marginLeft: 2,
                  color: isDarkMode ? "white" : "gray",
                  userSelect: "none",
                }}
              >
                {commentQty}
              </p>
            </div>
          </button>
        </div>
      </div>
    );
  };

  const PostSomething = () => {
    return (
      <div
        style={{ marginBottom: 5, cursor: "pointer" }}
        onClick={() => {
          setShowPostContainer(true);
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: 1,
          }}
        >
          {university === "I prefer not to disclose" ? (
            <FaUserCircle size={30} style={{ marginRight: 5 }} color="gray" />
          ) : (
            <img
              style={{ height: 30, marginRight: 5 }}
              src={require(`../assets/universities/${university}.png`)}
            />
          )}

          <label
            style={{
              fontSize: 13,
              margin: 0,
              width: "100%",
              fontWeight: "400",
              // border: "1px solid #cfcfcf",
              backgroundColor: isDarkMode ? "white" : "#f0f0f0",
              color: isDarkMode ? "gray" : "gray",
              padding: "12.5px",
              borderRadius: 20,
              cursor: "text",
              userSelect: "none", // This line prevents text selection
            }}
          >
            Any thoughts for today?
          </label>
        </div>
      </div>
    );
  };

  const formatTimeAgo = (date) => {
    const parsedDate = date.toDate ? date.toDate() : new Date(date); // Convert Firestore Timestamp to Date
    if (isNaN(parsedDate.getTime())) {
      return "Invalid date"; // Fallback for invalid dates
    }

    const now = Date.now();
    const diffInSeconds = Math.floor((now - parsedDate.getTime()) / 1000);

    if (diffInSeconds < 60) {
      return "now";
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes}m`;
    } else {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours}h`;
    }
  };

  const Sorter = () => {
    const [currentSort, setCurrentSort] = useState("Best");
    const [showModal, setShowModal] = useState(false);

    const sortingOptions = ["Best", "Hot", "New", "Top", "Rising"];

    const handleSortSelect = (option) => {
      setCurrentSort(option);
      setShowModal(false);
    };

    // Inline styles object
    const sortingStyle = {
      sorterButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: isDarkMode ? "white" : "#f0f0f0",
        color: isDarkMode ? "black" : "black",
        fontWeight: "500",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: 13,
        marginBottom: 10,
        outline: "none",
        boxShadow: "none",
        userSelect: "none",
        WebkitTapHighlightColor: "transparent",
      },
      modalOverlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      modalContent: {
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "8px",
        textAlign: "center",
        width: "300px",
      },
      sortOption: {
        backgroundColor: "white",
        border: "none",
        padding: "10px",
        width: "100%",
        textAlign: "left",
        cursor: "pointer",
        fontSize: "16px",
      },
      sortOptionHover: {
        backgroundColor: "white",
      },
      sortOptionSelected: {
        fontWeight: "bold",
        color: "#0079d3",
      },
      closeModal: {
        marginTop: "10px",
        padding: "8px 12px",
        backgroundColor: "#0079d3",
        color: "white",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
      },
    };

    return (
      <div>
        {/* Sorter Button */}
        <button
          onClick={() => setShowModal(!showModal)}
          style={sortingStyle.sorterButton}
        >
          {currentSort}{" "}
          <RiArrowDropDownLine style={{ marginLeft: 5 }} size={20} />
        </button>

        {/* Modal for Sorting Options */}
        {showModal && (
          <div style={sortingStyle.modalOverlay}>
            <div style={sortingStyle.modalContent}>
              <h3>Sort by</h3>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  backgroundColor: "white",
                }}
              >
                {sortingOptions.map((option) => (
                  <li key={option}>
                    <button
                      onClick={() => handleSortSelect(option)}
                      style={{
                        ...sortingStyle.sortOption,
                        ...(option === currentSort
                          ? sortingStyle.sortOptionSelected
                          : {}),
                        ...(option !== currentSort
                          ? sortingStyle.sortOptionHover
                          : {}),
                      }}
                    >
                      {option}
                    </button>
                  </li>
                ))}
              </ul>
              <button
                onClick={() => setShowModal(false)}
                style={sortingStyle.closeModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const BorderBottom = () => {
    return (
      <div
        style={{
          borderBottom: "2px solid #ccc", // Adjust color and thickness as needed
          margin: "20px 0", // Adds vertical space (gap) between the components
        }}
      />
    );
  };

  const LoadingScreen = () => {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "black",
          opacity: 0.7,
          zIndex: 9999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactLoading type="spin" color="#FF66C4" height={40} width={40} />
      </div>
    );
  };

  async function uploadPost() {
    setIsLoading(true);
    try {
      const postsCollection = collection(db, "Posts");
      await addDoc(postsCollection, {
        user: {
          userID: user.uid, // Unique user ID
          displayName: displayName, // User's display name
          university: university, // User's university (if applicable)
        },
        post: {
          title: postTitle, // Post title
          text: postText, // Post text/content
        },
        timestamp: firestoreTimestamp(), // Timestamp for when the post is created
        loveReactorIDs: [], // Array to track user IDs who reacted with "Love"
        laughReactorIDs: [], // Array to track user IDs who reacted with "Laugh"
        angryReactorIDs: [], // Array to track user IDs who reacted with "Angry"
        commentIDs: [], // Array to track associated comment IDs
      });

      // Reset the form and close the post container after successful upload
      setShowPostContainer(false);
      setPostTitle("");
      setPostText("");
    } catch (error) {
      console.error("Error uploading post:", error);
    } finally {
      setIsLoading(false); // Ensure loading state is updated
    }
  }

  useEffect(() => {
    // Automatically focus the textarea when the component mounts
    if (showPostContainer) {
      if (postTitleRef.current) {
        postTitleRef.current.focus();
      }
    }
  }, [showPostContainer]);

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);

      const postsCollection = collection(db, "Posts");
      const postsQuery = query(postsCollection, orderBy("timestamp", "desc"));
      const querySnapshot = await getDocs(postsQuery);

      // Assuming you have access to the current user's UID
      const { uid } = user;

      const posts = [];
      const reactions = [];

      querySnapshot.docs.forEach((doc) => {
        const data = doc.data();

        // Populate post data
        posts.push({
          id: doc.id,
          ...data,
          loveQty: data.loveReactorIDs?.length || 0,
          laughQty: data.laughReactorIDs?.length || 0,
          angryQty: data.angryReactorIDs?.length || 0,
        });

        // Check for the user's reaction
        if (data.loveReactorIDs?.includes(uid)) {
          reactions.push({ postID: doc.id, reaction: "love" });
        } else if (data.laughReactorIDs?.includes(uid)) {
          reactions.push({ postID: doc.id, reaction: "laugh" });
        } else if (data.angryReactorIDs?.includes(uid)) {
          reactions.push({ postID: doc.id, reaction: "angry" });
        }
      });

      setPostDATA(posts); // Initialize post data
      setReactedPosts(reactions); // Initialize the user's reactions
      setIsLoading(false);
    };

    fetchPosts();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {isMobile ? (
            // mobile layout
            <div
              style={{
                padding: 20,
                backgroundColor: isDarkMode ? "#282c34" : "#fffcfc",
                minHeight: "100vh", // Ensures it always covers the viewport
                display: "flex",
                flexDirection: "column", // Allows stacking content
              }}
            >
              <div
                style={{
                  maxWidth: 800,
                }}
              >
                {isReminderModalOpen ? (
                  <div
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent background
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1000,
                    }}
                  >
                    <div
                      style={{
                        marginLeft: 30,
                        marginRight: 30,
                        backgroundColor: "white",
                        padding: "20px",
                        borderRadius: "10px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 15,
                          fontWeight: "bold",
                          textAlign: "center",
                          margin: 0,
                          marginBottom: 20,
                        }}
                      >
                        Reminder
                      </p>
                      <p
                        style={{
                          fontSize: 11,
                          // textAlign: "center",
                          margin: 0,
                          marginBottom: 10,
                        }}
                      >
                        Your profile is stored in a temporary state. Once the
                        site is refreshed, any future notifications tied to the
                        current profile will be lost.
                      </p>
                      <p style={{ fontSize: 11, margin: 0, marginBottom: 10 }}>
                        You can navigate through the Main Menu without losing
                        your profile by pressing the back button
                      </p>
                      <p style={{ fontSize: 11, margin: 0 }}>Have fun!</p>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <button
                          onClick={() => {
                            setIsReminderModalOpen(false);
                          }}
                          style={{
                            paddingLeft: 20,
                            paddingRight: 20,
                            border: "none",
                            background: "none",
                            backgroundColor: "#ff66c4",
                            color: "white",
                            fontSize: 12,
                            fontWeight: "bold",
                            borderRadius: 10,
                            cursor: "pointer",
                          }}
                        >
                          <p>Close</p>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: isDarkMode ? "black" : "white",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 100, // Ensures it appears above other content
                    padding: "10px", // Optional for spacing
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={require("../mainlogo.png")}
                      alt="Logo"
                      style={{
                        borderRadius: "100%",
                        height: "25px",
                        marginRight: "5px",
                      }}
                    />

                    <p
                      style={{
                        marginBottom: 0,
                        fontSize: 15,
                        marginTop: 0,
                        marginLeft: 5,
                        color: isDarkMode ? "white" : "#004AAD",
                        fontWeight: "bold",
                      }}
                    >
                      DailyThoughts
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      style={{
                        display: "flex",
                        background: "none",
                        border: "none",
                        padding: 0,
                        margin: 0,
                        width: "auto",
                        height: "auto",
                        appearance: "none",
                        cursor: "pointer",
                        marginRight: 5,
                      }}
                      onClick={() => setShowPostContainer(true)} // Switch to light mode
                    >
                      <IoAddSharp
                        size={25}
                        color={isDarkMode ? "white" : "black"}
                      />
                    </button>

                    {/* Moon Button - Dark Mode */}
                    {isDarkMode ? (
                      <button
                        style={{
                          display: "flex",
                          background: "none",
                          border: "none",
                          padding: 0,
                          margin: 0,
                          width: "auto",
                          height: "auto",
                          appearance: "none",
                          cursor: "pointer",
                          marginRight: 5,
                        }}
                        onClick={() => setIsDarkMode(false)} // Switch to light mode
                      >
                        <IoSunnyOutline size={25} color={"white"} />
                      </button>
                    ) : (
                      // Sun Button - Light Mode
                      <button
                        style={{
                          display: "flex",
                          background: "none",
                          border: "none",
                          padding: 0,
                          margin: 0,
                          width: "auto",
                          height: "auto",
                          appearance: "none",
                          cursor: "pointer",
                          marginRight: 5,
                        }}
                        onClick={() => setIsDarkMode(true)} // Switch to dark mode
                      >
                        <IoMoonOutline size={25} color={"black"} />
                      </button>
                    )}

                    {/* Notification Button */}
                    <button
                      style={{
                        display: "flex",
                        background: "none",
                        border: "none",
                        padding: 0,
                        margin: 0,
                        width: "auto",
                        height: "auto",
                        appearance: "none",
                        cursor: "pointer",
                      }}
                    >
                      <IoNotificationsOutline
                        size={25}
                        color={isDarkMode ? "white" : "black"}
                      />
                      <p
                        style={{
                          position: "absolute",
                          right: 11,
                          backgroundColor: "#ff66c4",
                          borderRadius: 100,
                          margin: 0,
                          paddingLeft: 3,
                          paddingRight: 3,
                          paddingTop: 1.5,
                          paddingBottom: 1.5,
                          fontSize: 11,
                          fontWeight: "bolder",
                          color: "white",
                        }}
                      >
                        1
                      </p>
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 5,
                    marginTop: 30,
                  }}
                >
                  <p
                    style={{
                      fontSize: 9,
                      marginRight: 5,
                      fontWeight: "300",
                      color: isDarkMode ? "lightgray" : "black",
                    }}
                  >
                    Interacting as
                  </p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ProfilePicture university={university} />
                    <p
                      style={{
                        margin: 0,
                        fontSize: 12,
                        fontWeight: "bold",
                        color: isDarkMode ? "white" : "black",
                      }}
                    >
                      {displayName}
                    </p>
                  </div>
                </div>

                {showPostContainer ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100vh",
                      backgroundColor: isDarkMode ? "#282c34" : "#fffcfc",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: isDarkMode ? "#282c34" : "#fffcfc",
                        marginTop: 10,
                      }}
                    >
                      <button
                        style={{
                          display: "flex",
                          border: "none",
                          background: "none",
                          padding: 0,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowPostContainer(false);
                          setPostTitle("");
                          setPostText("");
                        }}
                      >
                        <IoMdClose
                          size={25}
                          color={isDarkMode ? "white" : "black"}
                        />
                      </button>

                      <button
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          border: "none",
                          background: "none",
                          padding: 0,
                        }}
                        onClick={() => {
                          uploadPost();
                        }}
                      >
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            backgroundColor: "#ff66c4",
                            color: "white",
                            border: "none",
                            borderRadius: 10,
                            padding: "10px",
                            fontSize: 11,
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          Share
                        </p>
                      </button>
                    </div>
                    <input
                      maxLength={300}
                      ref={postTitleRef}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        setPostTitle(inputValue);
                      }}
                      style={{
                        marginTop: 10,
                        border: "none",
                        fontSize: 16,
                        fontWeight: "600",
                        outline: "none",
                        color: isDarkMode ? "white" : "black",
                        backgroundColor: isDarkMode ? "#282c34" : "#fffcfc",
                      }}
                      placeholder="Title"
                    />
                    <textarea
                      maxLength={3000}
                      ref={postMessageRef}
                      placeholder="Say something about it"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        setPostText(inputValue);
                      }}
                      value={postText}
                      style={{
                        marginTop: 5,
                        minHeight: 50,
                        maxHeight: 450,
                        fontSize: 14,
                        paddingBottom: 10,
                        fontFamily: "inherit",
                        fontWeight: "inherit",
                        lineHeight: "inherit",
                        backgroundColor: isDarkMode ? "#282c34" : "#fffcfc",
                        border: "none",
                        outline: "none",
                        overFlowY: "auto", // Hide the scrollbar
                        color: isDarkMode ? "lightgray" : "black",
                        resize: "none",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={
                      {
                        // marginTop: 40,
                      }
                    }
                  >
                    {/* mobile */}
                    <PostSomething />
                    <div style={{ marginBottom: 10 }}>
                      <p
                        style={{
                          fontSize: 10,
                          // userSelect: "none",
                          cursor: "text",
                          WebkitTapHighlightColor: "transparent",
                          color: isDarkMode ? "gray" : "black",
                        }}
                      >
                        Please be mindful of what you post here. While your
                        private chats are protected by end-to-end encryption,
                        the content you share here is visible to everyone and
                        monitored for adherence to our community guidelines.
                        Let's keep this space positive and respectful!
                      </p>
                    </div>
                    {postDATA.length === 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          height: "75vh",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 13,
                            fontWeight: "400",
                            color: "gray",
                          }}
                        >
                          No posts yet
                        </p>
                      </div>
                    ) : (
                      <Sorter />
                    )}
                    {postDATA.map((post) => {
                      // Determine the user's current reaction
                      const userReaction = post.loveReactorIDs.includes(
                        user.uid
                      )
                        ? "love"
                        : post.laughReactorIDs.includes(user.uid)
                        ? "laugh"
                        : post.angryReactorIDs.includes(user.uid)
                        ? "angry"
                        : null;

                      return (
                        <EachPost
                          key={post.id}
                          user={post.user}
                          postID={post.id}
                          post={post.post}
                          loveQty={post.loveReactorIDs.length}
                          laughQty={post.laughReactorIDs.length}
                          angryQty={post.angryReactorIDs.length}
                          userReaction={userReaction} // Pass user's current reaction
                          commentQty={post.commentIDs.length}
                          timestamp={post.timestamp}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          ) : (
            // desktop/laptop layout
            <div
              style={{
                display: "flex",
                width: "100%",
                backgroundColor: "#282c34",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh", // Ensures it always covers the viewport
                display: "flex",
                flexDirection: "column", // Allows stacking content
              }}
            >
              <img
                src={require("../mainlogo.png")}
                alt="Logo"
                style={{
                  borderRadius: 15,
                  height: 50,
                  marginBottom: 20,
                }}
              />
              <p style={{ color: "white", fontSize: 25, margin: 0 }}>
                DailyThoughts is currently only available for mobile phones.{" "}
              </p>
              <p style={{ color: "white", fontSize: 15, fontWeight: "300" }}>
                We’re working on the desktop version, which will be available
                soon. Stay tuned!
              </p>
              <div style={{ display: "flex", marginTop: 10, maxWidth: 350 }}>
                <button
                  onClick={() => {
                    navigate("/", { replace: true });
                  }}
                  disabled={!user}
                  style={{
                    flex: 1,
                    backgroundColor: user ? "#ff66c4" : "#ccc",
                    color: "white",
                    border: "none",
                    borderRadius: 30,
                    height: "40px",
                    cursor: user ? "pointer" : "not-allowed",
                  }}
                >
                  <h4 style={{ margin: 0, paddingLeft: 20, paddingRight: 20 }}>
                    Go back to Selection
                  </h4>
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DailyThoughts;
