import ReactDOM from "react-dom/client";
import { Routes, Route } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import Home from "./pages/Home";
import MainMenu from "./pages/MainMenu";
import ChatSession from "./pages/ChatSession";
import Protected from "./components/Protected";
import DailyThoughts from "./pages/DailyThoughts";
import Post from "./pages/Post";

export default function App() {
  return (
    <div>
      <AuthContextProvider>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/dailythoughts" element={<DailyThoughts />} />
          {/* Dynamic route for specific posts */}
          <Route exact path="/dailythoughts/post/:postID" element={<Post />} />
        </Routes>
      </AuthContextProvider>
    </div>
  );
}
