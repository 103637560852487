import React from "react";
import { Navigate } from "react-router";
import { UserAuth } from "../context/AuthContext";
import SigningIn from "../pages/SigningIn";

function Protected({ children }) {
  const { isOver18, isAgree, user } = UserAuth();

  if (!isOver18 || !isAgree) {
    return <Navigate to="/" />;
  } else {
    if (!user) {
      return <SigningIn />;
    } else {
      return children;
    }
  }
}

export default Protected;
